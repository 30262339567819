import { useCallback, useMemo } from 'react';
import {
  Row,
  Col,
  Typography,
  Button,
  List,
  Card,
  Space,
  Flex,
  Input,
  Alert,
} from 'antd';
import {
  AppstoreAddOutlined,
  CopyOutlined,
  SaveOutlined,
  WarningOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import {
  filter,
  get,
  groupBy,
  isEmpty,
  keys,
  map,
  some,
  reduce,
  uniq,
} from 'lodash';
import schedules from '../schedules';
import FormItem from 'antd/es/form/FormItem';

const WEEK_TRANSLATE = ['日', '一', '二', '三', '四', '五', '六'];

type CalendarHeaderProps = {
  value: Dayjs;
  onChange(date: Dayjs): void;
};

export function CalendarHeader({ value, onChange }: CalendarHeaderProps) {
  return (
    <Space align="baseline">
      <Row gutter={8}>
        <Col>
          <Button
            size="small"
            onClick={() => {
              onChange(value.subtract(14, 'days'));
            }}
          >
            上兩週
          </Button>
        </Col>
        <Col>
          <Button
            size="small"
            onClick={() => {
              onChange(value.add(14, 'days'));
            }}
          >
            下兩週
          </Button>
        </Col>
      </Row>
    </Space>
  );
}

type CalendarCellProps = {
  listData: schedules.MappingGroupByTimestamp;
  sortedListData: {
    id: string;
    name: string;
    rank: number;
    markColor: string;
    data: schedules.MappingGroupByTimestamp;
  }[];
  rowStyleSettings: { bgColor: string };
  locationTagId: string;
  locationTagName: string;
  disabled: boolean;
  isEditMode: boolean;
  onClickAdd: Function;
  onCopyParams: Function;
  onSaveNote: Function;
  onClickDelete: Function;
  noteData: { [time: number]: { [locationTagId: string]: string } };
  renderListData: Function;
  deliveredMeals: {
    [locationTagId: string]: {
      [providerId: string]: {
        locationTag: schedules.LocationTag;
        provider: schedules.Provider;
        time: number;
      }[];
    };
  };
  modal: any;
};

export function CalendarCell({
  listData,
  sortedListData,
  rowStyleSettings,
  locationTagName,
  locationTagId,
  disabled,
  isEditMode,
  onClickAdd,
  onCopyParams,
  onSaveNote,
  noteData,
  renderListData,
  deliveredMeals,
  onClickDelete,
  modal,
}: CalendarCellProps) {
  const allTimestamp = useMemo(() => keys(listData), [listData]);

  const checkSuggestion = useCallback(
    (timestamp: number) => {
      const listByProviderType = groupBy(listData[timestamp], 'provider.type');
      const needMatchProviderType = !(listByProviderType['健康餐']?.length > 0);
      const needCountProviderType = keys(listByProviderType).length < 3;
      const listByProviderId = map(listData[timestamp], 'provider.id');

      const lastMonthTimestamp = dayjs(timestamp)
        .subtract(1, 'month')
        .startOf('day')
        .valueOf();

      const repeatProvider = filter(listData[timestamp], (item: any) => {
        const findTarget = get(deliveredMeals, [
          locationTagId,
          item.provider.id,
        ]);
        if (!isEmpty(findTarget)) {
          return some(
            findTarget,
            (targetItem) =>
              Number(targetItem.time) <=
                Number(dayjs(timestamp).set('day', -2).valueOf()) &&
              Number(targetItem.time) >= lastMonthTimestamp
          );
        }
        return false;
      });

      const repeatProviderByVertical = uniq(
        reduce(
          sortedListData,
          (accumulate, locationTag) => {
            const target = filter(
              locationTag.data[timestamp],
              (meals) => listByProviderId.indexOf(meals.provider.id) !== -1
            );
            if (locationTag.id !== locationTagId && target.length > 0) {
              return accumulate.concat(...map(target, 'provider.name'));
            }
            return accumulate;
          },
          []
        )
      );

      return {
        isNeedImprove:
          needMatchProviderType ||
          needCountProviderType ||
          (map(repeatProvider, 'provider.name') || []).length > 0 ||
          repeatProviderByVertical.length > 0,
        typeCombination: needMatchProviderType,
        typeCount: needCountProviderType,
        repeatInLastMonth: map(repeatProvider, 'provider.name') || [],
        repeatProviderByVertical,
      };
    },
    [listData, locationTagId, deliveredMeals, sortedListData]
  );

  return (
    <Card style={{ background: rowStyleSettings.bgColor }}>
      <Typography.Title level={5} className="location-tag-title">
        {locationTagName}
      </Typography.Title>

      <div>
        {map(allTimestamp, (timestamp: number) => {
          timestamp = Number(timestamp);
          const note = noteData?.[timestamp]?.[locationTagId];

          const resultSuggestion = checkSuggestion(timestamp);

          return (
            <List.Item key={timestamp}>
              <Typography.Text strong>
                {dayjs(timestamp).format('YYYY-MM-DD')} (
                {WEEK_TRANSLATE[dayjs(timestamp).get('day')]})
              </Typography.Text>

              <Flex gap="small" className="mb-1">
                {/* 提示三角形 */}
                {!isEmpty(listData[timestamp]) &&
                  resultSuggestion.isNeedImprove && (
                    <Button
                      icon={<WarningOutlined />}
                      size="small"
                      danger
                      onClick={(e) => {
                        e.stopPropagation();

                        modal.info({
                          icon: null,
                          title: '配餐建議',
                          okText: '確認',
                          content: (
                            <>
                              <div>大樓：{locationTagName}</div>
                              <div>
                                日期：{dayjs(timestamp).format('YYYY-MM-DD')}
                              </div>
                              <ul>
                                {resultSuggestion.typeCombination && (
                                  <li>建議健康餐類型至少要有一間</li>
                                )}
                                {resultSuggestion.typeCount && (
                                  <li>建議至少有三個供應商類型</li>
                                )}
                                {resultSuggestion.repeatInLastMonth.length >
                                  0 && (
                                  <li>
                                    <span>
                                      建議移除近一個月內已配餐過的供應商：
                                    </span>
                                    {resultSuggestion.repeatInLastMonth.join(
                                      '、'
                                    )}
                                  </li>
                                )}
                                {resultSuggestion.repeatProviderByVertical
                                  .length > 0 && (
                                  <li>
                                    <span>
                                      建議移同一天在不同大樓已配餐過的供應商：
                                    </span>
                                    {resultSuggestion.repeatProviderByVertical.join(
                                      '、'
                                    )}
                                  </li>
                                )}
                              </ul>
                            </>
                          ),
                        });
                      }}
                    />
                  )}

                {/* 刪除全部供應商 */}
                {isEditMode && !isEmpty(listData[timestamp]) && (
                  <Button
                    icon={<DeleteOutlined />}
                    size="small"
                    danger
                    onClick={async () => {
                      const params = map(listData[timestamp], (item) => ({
                        time: timestamp,
                        locationTagId: item.locationTag.id,
                        providerId: item.provider.id,
                      }));
                      await onClickDelete(params);
                    }}
                  />
                )}

                {/* 複製參數 */}
                {!isEmpty(listData[timestamp]) && (
                  <Button
                    icon={<CopyOutlined />}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      onCopyParams(locationTagId, timestamp);
                    }}
                  />
                )}

                {/* 新增供應商至此 */}
                {!disabled && (
                  <Button
                    icon={<AppstoreAddOutlined />}
                    size="small"
                    className="button-insert"
                    onClick={async (e) => {
                      e.stopPropagation();
                      onClickAdd(locationTagId, timestamp);
                    }}
                  />
                )}
              </Flex>

              {isEditMode && (
                <Space.Compact
                  size="small"
                  block
                  style={{ marginTop: 3, marginBottom: 3 }}
                >
                  <FormItem name={['notes', timestamp, locationTagId]} noStyle>
                    <Input placeholder="請輸入備註" />
                  </FormItem>

                  <Button
                    icon={<SaveOutlined />}
                    type="primary"
                    onClick={() => onSaveNote(locationTagId, timestamp)}
                  />
                </Space.Compact>
              )}

              {!isEditMode && note && (
                <Alert
                  style={{ width: '100%' }}
                  message={note}
                  type="info"
                  showIcon
                />
              )}

              {renderListData(
                listData || Array<schedules.CellData>(),
                timestamp
              )}
            </List.Item>
          );
        })}
      </div>
    </Card>
  );
}
