import { Table } from 'antd';
import { useReceivablePlanReceivables } from '../../utils/api-hooks/receivable.hook';
import moment from 'moment';
import {
  RECEIVABLE_STATE,
  RECEIVABLE_STATE_COLOR,
  RECEIVABLE_STATE_LABEL,
} from '../../constants/receivable';

type ReceivablePlanReceivablesComponentProps = {
  id: string;
};

export function ReceivablePlanReceivablesComponent(
  props: ReceivablePlanReceivablesComponentProps
) {
  const { data: { data } = {}, isLoading } = useReceivablePlanReceivables({
    receivablePlanId: props.id,
  });

  return (
    <Table
      dataSource={data}
      loading={isLoading}
      pagination={false}
      rowKey="id"
      size="small"
      columns={[
        {
          width: 80,
          align: 'center',
          title: '狀態',
          dataIndex: 'state',
          render(value) {
            return (
              <span
                className={`text-${RECEIVABLE_STATE_COLOR[value as RECEIVABLE_STATE].toLowerCase()}-500`}
              >
                {RECEIVABLE_STATE_LABEL[value as RECEIVABLE_STATE]}
              </span>
            );
          },
        },
        {
          align: 'center',
          dataIndex: 'dueTime',
          title: '應收日期',
          width: 100,
          render(value) {
            return moment(value).format('YYYY/MM/DD');
          },
        },
        {
          title: '金額',
          width: 200,
          dataIndex: 'amount',
          key: 'amount',
          render(value) {
            return (
              value.toLocaleString({
                style: 'currency',
                currency: 'TWD',
                maximumFractionDigits: 0,
              }) + '元'
            );
          },
        },
        {
          title: '備註',
          dataIndex: 'note',
        },
      ]}
    />
  );
}
