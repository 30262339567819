import { map, sortBy } from 'lodash';
import { useMemo, useState } from 'react';
import {
  useCreateDriverEvent,
  useDeleteDriverEvent,
  useDriversSalary,
  useExportDriverSalary,
  useSendDriverSalary,
  useListDrivers,
} from '../../utils/api.hook';
import { usePagination } from '../../shared/usePagination';
import { DriversSalaryComponent } from './drivers-salary.component';
import dayjs, { Dayjs } from 'dayjs';

const THIS_MONTH_FIRST_DAY = dayjs().startOf('month');

export function DriversSalaryContainer() {
  const pagination = usePagination({
    defaultPageSize: 30,
  });
  const [timeRange, setTimeRange] = useState<{
    startTime: Dayjs;
    endTime: Dayjs;
  }>({
    startTime: THIS_MONTH_FIRST_DAY,
    endTime: THIS_MONTH_FIRST_DAY.add(14, 'day'),
  });
  const {
    data: { driversSalary = [], total } = {},
    isLoading: isFetchingDrivers,
  } = useDriversSalary({
    startTime: dayjs(timeRange.startTime).startOf('date').valueOf(),
    endTime: dayjs(timeRange.endTime).endOf('date').valueOf(),
  });
  const { data: { driversInfo = [] } = {} } = useListDrivers({
    startTime: dayjs(timeRange.startTime).startOf('date').valueOf(),
    endTime: dayjs(timeRange.endTime).endOf('date').valueOf(),
  });
  const { mutateAsync: createDriverEvent, isLoading: isCreatingDriver } =
    useCreateDriverEvent();
  const { mutateAsync: deleteDriverEvent, isLoading: isDeletingDriver } =
    useDeleteDriverEvent();
  const { mutateAsync: exportDriverSalary, isLoading: isExportLoading } =
    useExportDriverSalary();
  const { mutateAsync: sendDriverSalary, isLoading: isSendingDriverSalary } =
    useSendDriverSalary();

  const driverSalaryList = useMemo(() => {
    return sortBy(driversSalary, 'driver.idNumber');
  }, [driversSalary]);

  const driverList = useMemo(() => {
    return sortBy(
      map(driversInfo, ({ user, ...driver }) => {
        const { profileId, id, ...userInfo } = user;

        return {
          ...driver,
          ...userInfo,
          label: `${driver.idNumber} - ${userInfo.name}`,
          value: driver.id,
        };
      }),
      'idNumber'
    );
  }, [driversInfo]);

  return (
    <DriversSalaryComponent
      createDriverEvent={createDriverEvent}
      deleteDriverEvent={deleteDriverEvent}
      timeRange={timeRange}
      onChangeTimeRange={setTimeRange}
      exportDriverSalary={exportDriverSalary}
      isExportLoading={isExportLoading}
      sendDriverSalary={sendDriverSalary}
      isSendLoading={isSendingDriverSalary}
      driversSalary={driverSalaryList}
      driverList={driverList}
      pagination={{ ...pagination, total }}
      isLoading={isCreatingDriver || isDeletingDriver || isFetchingDrivers}
    />
  );
}
