import { useState } from 'react';
import { useOrderItemRefunds } from '../../utils/api.hook';
import { usePagination } from '../../shared/usePagination';
import { ProductRefundComponent } from './product-refund.component';
import { FilterValue } from 'antd/es/table/interface';

export function ProductRefundContainer() {
  const [filters, setFilters] = useState<Record<string, FilterValue | null>>(
    {}
  );
  const {
    data: { refunds, total, providerFilters, timeFilters } = {},
    isLoading,
  } = useOrderItemRefunds({
    ...filters,
    skip: 0,
    take: 15,
  });
  const pagination = usePagination();

  return (
    <ProductRefundComponent
      isLoading={isLoading}
      providerFilters={providerFilters || []}
      refunds={refunds || []}
      setFilters={setFilters}
      timeFilters={timeFilters || []}
      pagination={{
        ...pagination,
        total,
      }}
    />
  );
}
