import { Form, Input, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import { useEffect, useMemo } from 'react';
import { useLineBotJoinLogs } from '../utils/api.hook';
import { map } from 'lodash';
import moment from 'moment';
import {
  COMMERCIAL_TERMS,
  GAMBLING_DISCOUNT_TYPE,
} from '../constants/provider-form.types';
import { ProviderTypeSelectorComponent } from '../shared/provider-type-selector.component';

export function ProviderForm({
  form,
  data,
}: {
  form: FormInstance;
  data?: {
    address: string | null;
    addressComment: string | null;
    idNumber: string;
    lineGroupId: string;
    name: string;
    nickname: string;
    taxId: string | null;
    businessInfo: {
      chargeSlottingFee: boolean;
      commercialTerms: COMMERCIAL_TERMS | null;
      commission: number | null;
      gamblingRules: Array<{
        itemSoldQuantityGuarantee: number;
        discountType: GAMBLING_DISCOUNT_TYPE;
        discountAmount: number;
      }> | null;
    } | null;
    paymentInfo: {
      account: string | null;
      accountName: string | null;
      bankName: string | null;
      bankBranchName: string | null;
      receiptType: string | null;
      shouldInvoice: boolean;
    } | null;
  };
}) {
  const { data: { logs } = {} } = useLineBotJoinLogs();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const logOptions = useMemo(
    () =>
      map(logs, (log) => ({
        label: `${moment(log.time).utcOffset(8).format('YYYY-MM-DD HH:mm')} - ${
          log.id
        }`,
        value: log.id,
      })),
    [logs]
  );

  return (
    <Form form={form} initialValues={data} autoComplete="off">
      <FormItem
        name="idNumber"
        label="編號"
        rules={[
          {
            required: true,
            message: '請輸入編號',
          },
        ]}
      >
        <Input />
      </FormItem>
      <FormItem
        name="name"
        label="名稱"
        rules={[
          {
            required: true,
            message: '請輸入名稱',
          },
        ]}
      >
        <Input />
      </FormItem>
      <FormItem
        name="nickname"
        label="簡稱"
        rules={[
          {
            required: true,
            message: '請輸入簡稱',
          },
        ]}
      >
        <Input />
      </FormItem>
      <ProviderTypeSelectorComponent />
      <FormItem
        name="lineGroupId"
        label="LINE Group ID"
        rules={[
          {
            required: true,
            message: '請輸入LINE Group ID',
          },
        ]}
      >
        <Select options={logOptions} />
      </FormItem>
    </Form>
  );
}
