import {
  useCreateLocation,
  useDeleteLocation,
  useLocations,
  useUpdateLocation,
} from '../utils/api.hook';
import { usePagination } from '../shared/usePagination';
import { LocationsComponent } from './locations.component';

export function LocationsContainer() {
  const pagination = usePagination({
    defaultPageSize: 30,
  });
  const { mutateAsync: createLocation, isLoading: isCreatingLocation } =
    useCreateLocation();
  const { mutateAsync: updateLocation, isLoading: isUpdatingLocation } =
    useUpdateLocation();
  const { mutateAsync: deleteLocation, isLoading: isDeletingLocation } =
    useDeleteLocation();
  const { data: { locations, total } = {}, isLoading } = useLocations({
    skip: pagination.skip,
    take: pagination.take,
  });

  return (
    <LocationsComponent
      createLocation={createLocation}
      deleteLocation={deleteLocation}
      locations={locations || []}
      pagination={{ ...pagination, total }}
      updateLocation={updateLocation}
      isLoading={
        isLoading ||
        isCreatingLocation ||
        isUpdatingLocation ||
        isDeletingLocation
      }
    />
  );
}
