import { useMemo, useState } from 'react';
import { useOrders } from '../../utils/api.hook';
import { OrderStatisticsComponent } from './order-statistics.component';
import { find, forEach, get, map } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';

export function OrderStatisticsContainer() {
  const [redeemDate, setRedeemDate] = useState<[Dayjs, Dayjs]>([
    dayjs(),
    dayjs(),
  ]);
  const [orderState, setOrderState] = useState<
    Array<'已確認' | '未確認' | '取消訂單' | '取消訂單-退貨' | '結案'>
  >(['已確認']);
  const {
    data: { orders, locations } = {},
    isLoading,
    isFetching,
  } = useOrders({
    redeemTimeRange: map(redeemDate, (date) => date.toISOString()),
    state: orderState,
  });
  const [locationCounts, providerCounts, providerTypeData, districtCounts] =
    useMemo(() => {
      const locationResult: Record<string, number> = {};
      const providerResult: Record<string, number> = {};
      const providerTypeResult: Record<string, number> = {};
      const locationDistrictResult: Record<string, number> = {};
      forEach(orders, (order) => {
        const { quantity, address, providerName } = order;
        const type = get(order, ['product', 'provider', 'type']);
        const district = find(locations, ['name', address])?.district;

        locationResult[address] = (locationResult[address] || 0) + quantity;
        providerResult[providerName] =
          (providerResult[providerName] || 0) + quantity;
        providerTypeResult[type] = (providerTypeResult[type] || 0) + quantity;

        locationDistrictResult[district] =
          (locationDistrictResult[district] || 0) + quantity;
      });

      return [
        map(locationResult, (value, id) => ({
          id,
          value,
          idNumber: find(locations, ['name', id])?.idNumber || 0,
        })),
        map(providerResult, (value, id) => ({ id, value })),
        map(providerTypeResult, (value, type) => ({ type, value })),
        map(locationDistrictResult, (value, type) => ({ type, value })),
      ];
    }, [orders, locations]);

  return (
    <OrderStatisticsComponent
      districtCounts={districtCounts}
      isLoading={isLoading || isFetching}
      locationCounts={locationCounts || []}
      onDateChange={setRedeemDate}
      onOrderStateChange={setOrderState}
      orderState={orderState}
      providerCounts={providerCounts || []}
      providerTypeData={providerTypeData}
      redeemDate={redeemDate}
    />
  );
}
