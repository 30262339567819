import { Space, Table } from 'antd';
import { ColumnType } from 'antd/es/table/interface';

type Props = {
  isLoading: boolean;
  ranking: any[];
};

export function RecommendationsComponent({ isLoading, ranking }: Props) {
  const columns: ColumnType<Props['ranking'][number]>[] = [
    {
      title: '用戶名稱',
      dataIndex: 'username',
    },
    {
      title: '用戶Email',
      dataIndex: 'email',
    },
    {
      title: '推薦人數',
      dataIndex: 'newUserCount',
    },
    {
      title: '完成訂單人數',
      dataIndex: 'completeUserCount',
    },
  ];

  return (
    <Space direction="vertical" className="w-full">
      <div className="text-2xl font-bold">推薦用戶排名</div>
      <Table
        dataSource={ranking}
        loading={isLoading}
        rowKey="email"
        size="small"
        pagination={false}
        columns={columns}
        scroll={{ x: 'max-content' }}
      />
    </Space>
  );
}
