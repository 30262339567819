import { Card, DatePicker, Statistic } from 'antd';
import Table, { ColumnType } from 'antd/es/table';
import dayjs, { Dayjs } from 'dayjs';
import locale from 'antd/es/date-picker/locale/zh_TW';

type Props = {
  isCountLoading: boolean;
  isSummaryLoading: boolean;
  onDateChange: (dates: any, dateStrings: string[]) => void;
  timeRange: [Dayjs, Dayjs];
  count: number;
  summaryList: {
    point: number;
    time: number;
    type: 'share_member' | 'new_member';
    usePoint: number;
  }[];
};

export function RecommendationStatisticsComponent(props: Props) {
  const columns: ColumnType<Props['summaryList'][number]>[] = [
    {
      title: '日期',
      dataIndex: 'time',
      render: (value: number) => dayjs(value).format('YYYY-MM-DD'),
    },
    {
      title: '類型',
      dataIndex: 'type',
      render: (value: Props['summaryList'][number]['type']) => {
        if (value === 'share_member') {
          return '推薦人';
        }
        return '被推薦人';
      },
    },
    {
      title: '獲得點數',
      dataIndex: 'point',
    },
    {
      title: '使用點數',
      dataIndex: 'usePoint',
    },
  ];

  return (
    <div className="flex flex-col gap-y-4">
      <div className="text-2xl font-bold">推薦用戶統計</div>
      <div className="flex items-center gap-x-2">
        <span>篩選日期範圍</span>
        <DatePicker.RangePicker
          allowClear={false}
          locale={locale}
          onChange={props.onDateChange}
          value={props.timeRange}
          maxDate={dayjs().startOf('date')}
          presets={[
            {
              label: '今天',
              value: [dayjs().startOf('date'), dayjs().endOf('date')],
            },
            {
              label: '昨天',
              value: [
                dayjs().startOf('date').subtract(1, 'day'),
                dayjs().endOf('date').subtract(1, 'day'),
              ],
            },
            {
              label: '本週',
              value: [dayjs().startOf('week'), dayjs().endOf('week')],
            },
            {
              label: '上週',
              value: [
                dayjs().startOf('week').subtract(1, 'week'),
                dayjs().endOf('week').subtract(1, 'week'),
              ],
            },
            {
              label: '本月',
              value: [dayjs().startOf('month'), dayjs().endOf('month')],
            },
            {
              label: '前月',
              value: [
                dayjs().startOf('month').subtract(1, 'month'),
                dayjs().endOf('month').subtract(1, 'month'),
              ],
            },
          ]}
        />
      </div>

      <Card>
        <Statistic
          title="新用戶"
          value={props.count}
          loading={props.isCountLoading}
        />
      </Card>

      <div className="flex gap-4 flex-grow shrink [&>*]:flex-grow [&>*]:basis-1/2">
        <Table
          dataSource={props.summaryList}
          loading={props.isSummaryLoading}
          rowKey={(record) =>
            `${record.time}_${record.type}_${record.point}_${record.usePoint}`
          }
          size="small"
          pagination={false}
          columns={columns}
          scroll={{ x: 'max-content' }}
        />
      </div>
    </div>
  );
}
