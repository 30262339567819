import { Button, Form, Modal, Space, Table, TablePaginationConfig } from 'antd';
import { ColumnType } from 'antd/es/table';
import { LocationFormComponent } from './location-form.component';
import { Color } from 'antd/es/color-picker';

type LocationsComponentProps = {
  locations: {
    id: string;
    name: string;
    idNumber: number;
    backgroundColor: string;
    address: string | null;
    comment: string | null;
    district: string | null;
    city: string | null;
    recycleBagsWeight: number;
  }[];
  isLoading: boolean;
  pagination: TablePaginationConfig;
  createLocation: (data: {
    idNumber: number;
    name: string;
    backgroundColor: string;
    address: string | null;
    comment: string | null;
    district: string | null;
    city: string | null;
    recycleBagsWeight: number;
  }) => any;
  updateLocation: (data: {
    backgroundColor: string;
    id: string;
    idNumber: number;
    name: string;
    address: string | null;
    comment: string | null;
    district: string | null;
    city: string | null;
    recycleBagsWeight: number;
  }) => any;
  deleteLocation: (id: string) => any;
};

export function LocationsComponent(props: LocationsComponentProps) {
  const [modal, contextHolder] = Modal.useModal();
  const [form] = Form.useForm<{
    idNumber: number;
    name: string;
    backgroundColor: Color;
    address: string | null;
    comment: string | null;
    district: string | null;
    city: string | null;
    recycleBagsWeight: number;
  }>();

  const onAddButtonClick = () => {
    modal.confirm({
      cancelText: '取消',
      content: <LocationFormComponent form={form} />,
      icon: null,
      okText: '新增',
      title: '新增大樓',
      onOk: async () => {
        const data = await form.validateFields();

        return props.createLocation({
          ...data,
          backgroundColor: data.backgroundColor.toHexString(),
        });
      },
    });
  };

  const onEditButtonClick = (
    location: LocationsComponentProps['locations'][number]
  ) => {
    modal.confirm({
      cancelText: '取消',
      content: <LocationFormComponent form={form} location={location} />,
      icon: null,
      okText: '儲存',
      title: '編輯大樓',
      onOk: async () => {
        const data = await form.validateFields();
        const id = location.id;

        return props.updateLocation({
          ...data,
          id,
          backgroundColor: data.backgroundColor.toHexString(),
        });
      },
    });
  };

  const onDeleteButtonClick = (
    location: LocationsComponentProps['locations'][number]
  ) => {
    modal.confirm({
      title: '刪除大樓',
      content: (
        <Space direction="vertical">
          <div>確定要刪除 {location.name} 嗎？</div>
          <div>刪除後無法復原</div>
        </Space>
      ),
      okText: '確認',
      cancelText: '取消',
      onOk: () => {
        return props.deleteLocation(location.id);
      },
    });
  };

  const columns: ColumnType<LocationsComponentProps['locations'][number]>[] = [
    {
      align: 'center',
      dataIndex: 'idNumber',
      key: 'idNumber',
      title: '編號',
      width: 50,
    },
    {
      align: 'center',
      dataIndex: 'backgroundColor',
      key: 'backgroundColor',
      title: '背景顏色',
      width: 80,
      render: (backgroundColor) => (
        <div
          className="inline-block w-6 h-6 rounded-full"
          style={{ backgroundColor: backgroundColor }}
        />
      ),
    },
    {
      align: 'center',
      dataIndex: 'recycleBagsWeight',
      title: '回收保溫袋權重',
      width: 120,
    },
    {
      width: 200,
      title: '名稱',
      dataIndex: 'name',
      key: 'name',
    },
    {
      width: 60,
      title: '城市',
      dataIndex: 'city',
      key: 'city',
    },
    {
      width: 60,
      title: '行政區',
      dataIndex: 'district',
      key: 'district',
    },
    {
      title: '地址',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: '備註',
      dataIndex: 'comment',
    },
    {
      title: '操作',
      key: 'action',
      width: 100,
      render: (record) => (
        <Space>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              onEditButtonClick(record);
            }}
          >
            編輯
          </Button>
          <Button
            danger
            size="small"
            onClick={() => {
              onDeleteButtonClick(record);
            }}
          >
            刪除
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Space direction="vertical" className="w-full">
      {contextHolder}
      <div className="text-2xl font-bold">大樓管理</div>
      <Table
        columns={columns}
        dataSource={props.locations}
        loading={props.isLoading}
        pagination={props.pagination}
        rowKey="id"
        size="small"
        scroll={{ x: 'max-content' }}
        locale={{
          emptyText: '沒有任何大樓',
        }}
        title={() => (
          <Button type="primary" onClick={onAddButtonClick}>
            新增大樓
          </Button>
        )}
      />
    </Space>
  );
}
