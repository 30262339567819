import { useState } from 'react';
import {
  useCreateProviderEvent,
  useDeleteProviderEvent,
  useProviderEvents,
  useUpdateProviderEvent,
} from '../../utils/api.hook';
import { usePagination } from '../../shared/usePagination';
import { ProviderEventsComponent } from './provider-events.component';
import { FilterValue } from 'antd/es/table/interface';

export function ProviderEventsContainer() {
  const pagination = usePagination();
  const [filters, setFilters] = useState<Record<string, FilterValue>>({});
  const {
    data: { events, timeFilters, providerFilters, total } = {},
    isLoading,
    isFetching,
  } = useProviderEvents({
    ...filters,
    skip: pagination.skip,
    take: pagination.take,
  });
  const {
    mutateAsync: createProviderEvent,
    isLoading: isCreatingProviderEvent,
  } = useCreateProviderEvent();
  const {
    mutateAsync: updateProviderEvent,
    isLoading: isUpdatingProviderEvent,
  } = useUpdateProviderEvent();
  const {
    mutateAsync: deleteProviderEvent,
    isLoading: isDeletingProviderEvent,
  } = useDeleteProviderEvent();

  return (
    <ProviderEventsComponent
      createProviderEvent={createProviderEvent}
      deleteProviderEvent={deleteProviderEvent}
      events={events || []}
      providerFilters={providerFilters || []}
      setFilters={setFilters}
      timeFilters={timeFilters || []}
      updateProviderEvent={updateProviderEvent}
      pagination={{
        ...pagination,
        total,
      }}
      isLoading={
        isLoading ||
        isFetching ||
        isCreatingProviderEvent ||
        isUpdatingProviderEvent ||
        isDeletingProviderEvent
      }
    />
  );
}
