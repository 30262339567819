import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  CreateReceivablePlanDto,
  ReceivablePlanApi,
  UpdateReceivablePlanDto,
} from '../api-client';

export function useCreateReceivablePlan() {
  const queryClient = useQueryClient();

  return useMutation(
    (data: CreateReceivablePlanDto) => {
      return new ReceivablePlanApi().receivablePlanControllerCreateReceivablePlan(
        data
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('receivablePlans');
      },
    }
  );
}

export function useReceivablePlans(
  data: {
    providerId: string | undefined;
    skip: number | undefined;
    take: number | undefined;
  } = {
    providerId: undefined,
    skip: undefined,
    take: undefined,
  }
) {
  const { providerId, skip, take } = data;

  return useQuery(['receivablePlans', data], () => {
    return new ReceivablePlanApi()
      .receivablePlanControllerFindReceivablePlans(providerId, skip, take)
      .then((response) => {
        return response.data;
      });
  });
}

export function useUpdateReceivablePlan() {
  const queryClient = useQueryClient();

  return useMutation(
    (data: UpdateReceivablePlanDto & { id: string }) => {
      const { id, ...rest } = data;

      return new ReceivablePlanApi().receivablePlanControllerUpdateReceivablePlan(
        data.id,
        rest
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('receivablePlans');
      },
    }
  );
}

export function useDeleteReceivablePlan() {
  const queryClient = useQueryClient();

  return useMutation(
    (id: string) => {
      return new ReceivablePlanApi().receivablePlanControllerDeleteReceivablePlan(
        id
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('receivablePlans');
      },
    }
  );
}
