import { Form, Input, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { isEmpty, map } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useLineBotDriverGroupLogs } from '../utils/api.hook';

export function DriversForm(props: {
  form: FormInstance;
  data?: {
    id: string;
    idNumber: string;
    name: string;
    lineGroupId?: string;
    address: string;
    phone: string;
    carNumber: string;
    carModel: string;
    carColor: string;
    bankCode?: string;
    bankAccount?: string;
    bankName?: string;
  };
}) {
  const { data: { logs } = {} } = useLineBotDriverGroupLogs();

  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  const logOptions = useMemo(
    () =>
      map(logs, (log) => ({
        label: `${moment(log.time).utcOffset(8).format('YYYY-MM-DD HH:mm')} - ${
          log.name
        }`,
        value: log.id,
      })),
    [logs]
  );

  return (
    <Form form={props.form} autoComplete="off" initialValues={props.data}>
      <Form.Item
        label="代號"
        name="idNumber"
        rules={[{ required: true, message: '請輸入代號' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="名字"
        name="name"
        rules={[{ required: true, message: '請輸入名字' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="地址"
        name="address"
        rules={[{ required: true, message: '請輸入地址' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="電話"
        name="phone"
        rules={[{ required: true, message: '請輸入電話' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="車牌"
        name="carNumber"
        rules={[{ required: true, message: '請輸入車牌' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="車型"
        name="carModel"
        rules={[{ required: false, message: '請輸入車型' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="車色"
        name="carColor"
        rules={[{ required: false, message: '請輸入車色' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="lineGroupId" label="LINE Group ID">
        <Select options={logOptions} allowClear />
      </Form.Item>
      <Form.Item
        label="銀行代碼"
        name="bankCode"
        rules={[
          {
            validator: async (rule, value) => {
              if (!isEmpty(value) && !/^\d{3}$/.test(value)) {
                throw new Error('請輸入有效的銀行代碼');
              }
            },
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="銀行名稱" name="bankName">
        <Input />
      </Form.Item>
      <Form.Item
        label="匯款帳號"
        name="bankAccount"
        rules={[
          {
            validator: async (rule, value) => {
              if (!isEmpty(value) && !/^^[0-9-]+$/.test(value)) {
                throw new Error('請輸入有效的匯款帳號');
              }
            },
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
}
