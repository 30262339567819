import { message } from 'antd';
import axios from 'axios';
import { Dayjs } from 'dayjs';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { REFUND_REASON } from '../constants/refund-reason';
import { PROVIDER_EVENT_TYPES } from '../constants/provider-event.types';
import { PROVIDER_STATUS_TYPE } from '../constants/schedule-meals.types';
import { useAuthStore } from './stores/useAuthStore';
import { STATEMENT_STATE } from '../constants/statement-state';
import { Key } from 'react';
import {
  CreateReceivableDto,
  Message,
  OrderItemsApi,
  OrdersApi,
  ProductControllerGetProductsStateEnum,
  ProductsApi,
  ProviderStatementsApi,
  ReceivablesApi,
  ResolveStatementDto,
  SendMenuDto,
  UpdateOrderItemDto,
  UpdateProductDto,
  UpdateReceivableDto,
  UpdateUserDto,
  UsersApi,
} from './api-client';

export function useProviders(data: { keyword: string }) {
  return useQuery(['providers', data], async () => {
    const response = await axios.get('/providers', {
      params: data,
    });

    return response.data;
  });
}

export function useListProviders() {
  return useQuery('providers', async () => {
    const response = await axios.get('/providers/list');

    return response.data;
  });
}

export function useCreateProvider() {
  const queryClient = useQueryClient();

  return useMutation(
    ['providers', 'create'],
    (data: { name: string; lineGroupId: string }) => {
      return axios.post('/providers', data);
    },
    {
      onSuccess: () => {
        message.success('新增成功');
        queryClient.invalidateQueries('providers');
        queryClient.invalidateQueries('lineBotJoinLogs');
      },
    }
  );
}

export function useUpdateProvider() {
  const queryClient = useQueryClient();

  return useMutation(
    ['provider', 'update'],
    (data: { id: string; name: string; lineGroupId: string }) => {
      return axios.patch(`/providers/${data.id}`, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('providers');
        queryClient.invalidateQueries('provider');
        queryClient.invalidateQueries('lineBotJoinLogs');
        message.success('更新成功');
      },
    }
  );
}

export function useDeleteProvider() {
  const queryClient = useQueryClient();

  return useMutation(
    ['providers', 'delete'],
    (id: string) => {
      return axios.delete(`/providers/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('providers');
      },
    }
  );
}

export function useOrders(data: {
  skip?: number;
  take?: number;
  startTime?: Dayjs;
  endTime?: Dayjs;
  address?: string[];
  state?: Array<'已確認' | '未確認' | '取消訂單' | '取消訂單-退貨' | '結案'>;
  redeemTime?: string[];
  redeemTimeRange?: string[];
  providerNames?: string[];
  keyword?: string;
}) {
  return useQuery(['orders', data], async () => {
    const response = await axios.get('/orders', {
      params: {
        address: data.address,
        endTime: data.endTime,
        keyword: data.keyword,
        providerNames: data.providerNames,
        redeemTime: data.redeemTime,
        redeemTimeRange: data.redeemTimeRange,
        skip: data.skip,
        startTime: data.startTime,
        state: data.state,
        take: data.take,
      },
    });

    return response.data;
  });
}

export function useExportOrders(data: {
  timeRange: [Dayjs, Dayjs] | undefined;
}) {
  return useQuery(
    ['orders', 'export', data],
    async () => {
      if (!data.timeRange) return;

      const response = await axios.get('/orders/export', {
        responseType: 'arraybuffer',
        params: data,
      });

      // 下載檔案
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/xlsx' })
      );
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `訂單資料.xlsx`);

      document.body.appendChild(link);

      link.click();
      link.remove();

      return response.data;
    },
    {
      cacheTime: 0,
    }
  );
}

export function useSendMenu() {
  return useMutation(
    ['orders', 'sendMenu'],
    (data: SendMenuDto) => {
      return new OrdersApi().orderControllerSendMenu(data);
    },
    {
      onSuccess: () => {
        message.success('發送成功');
      },
    }
  );
}

export function useLineBotJoinLogs() {
  return useQuery('lineBotJoinLogs', async () => {
    const response = await axios.get('/line_bot');

    return response.data;
  });
}

export function useCreateProviderTag() {
  const queryClient = useQueryClient();

  return useMutation(
    ['providerTags', 'create'],
    (data: { name: string; description: string }) => {
      return axios.post('/provider_tags', data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('providerTags');
      },
    }
  );
}

export function useUpdateProviderTag() {
  const queryClient = useQueryClient();

  return useMutation(
    ['providerTags', 'update'],
    (data: { id: string; name: string; description: string }) => {
      return axios.patch(`/provider_tags/${data.id}`, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('providerTags');
      },
    }
  );
}

export function useDeleteProviderTag() {
  const queryClient = useQueryClient();

  return useMutation(
    ['providerTags', 'delete'],
    (id: string) => {
      return axios.delete(`/provider_tags/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('providerTags');
      },
    }
  );
}

export function useProviderTags() {
  return useQuery('providerTags', async () => {
    const response = await axios.get('/provider_tags');

    return response.data;
  });
}

export function useExportMenu(data: { date: Dayjs | undefined }) {
  return useQuery(
    ['orders', 'exportMenu', data],
    async () => {
      const { date } = data;

      if (!date) throw new Error('請選擇日期');

      const response = await axios.get('/orders/export_menu', {
        responseType: 'arraybuffer',
        params: {
          date: data.date?.toDate(),
        },
      });

      // 下載檔案
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/pdf' })
      );
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `${date.format('YYYY-MM-DD')}發餐單.pdf`);

      document.body.appendChild(link);

      link.click();
      link.remove();

      return response.data;
    },
    {
      cacheTime: 0,
      enabled: false,
    }
  );
}

export function useLocations(data?: { skip?: number; take?: number }) {
  return useQuery(['locations', data], async () => {
    const response = await axios.get<{
      locations: {
        id: string;
        name: string;
        idNumber: number;
        backgroundColor: string;
        address: string;
        comment: string;
        recycleBagsWeight: number;
        city: string | null;
        district: string | null;
      }[];
      total: number;
    }>('/locations', {
      params: data,
    });

    return response.data;
  });
}

export function useLocationTags() {
  return useQuery('locationTags', async () => {
    const response = await axios.get('/location_tags');

    return response.data;
  });
}

export function useCreateLocationTag() {
  const queryClient = useQueryClient();

  return useMutation(
    ['locationTags', 'create'],
    (data: {
      name: string;
      description: string;
      pay: number;
      textColor: string;
      backgroundColor: string;
    }) => {
      return axios.post('/location_tags', data);
    },
    {
      onSuccess: () => {
        message.success('新增成功');
        queryClient.invalidateQueries('locationTags');
        queryClient.invalidateQueries('driversSalary');
      },
    }
  );
}

export function useUpdateLocationTag() {
  const queryClient = useQueryClient();

  return useMutation(
    ['locationTags', 'update'],
    (data: {
      id: string;
      name: string;
      description: string;
      pay: number;
      textColor: string;
      backgroundColor: string;
    }) => {
      return axios.patch(`/location_tags/${data.id}`, data);
    },
    {
      onSuccess: () => {
        message.success('更新成功');
        queryClient.invalidateQueries('locationTags');
        queryClient.invalidateQueries('driversSalary');
      },
    }
  );
}

export function useDeleteLocationTag() {
  const queryClient = useQueryClient();

  return useMutation(
    ['locationTags', 'delete'],
    (id: string) => {
      return axios.delete(`/location_tags/${id}`);
    },
    {
      onSuccess: () => {
        message.success('刪除成功');
        queryClient.invalidateQueries('locationTags');
        queryClient.invalidateQueries('driversSalary');
      },
    }
  );
}

export function useExportPosData(data: {
  date: Dayjs | undefined;
  locationIds: string[];
  layoutType: string;
}) {
  return useQuery(
    ['orders', data],
    async () => {
      const { date } = data;

      if (!date) throw new Error('請選擇日期');
      if (data.locationIds.length === 0) throw new Error('請選擇大樓');

      const response = await axios.get('/orders/export_pos_data', {
        responseType: 'arraybuffer',
        params: {
          date: data.date?.toDate(),
          locationIds: data.locationIds,
          layoutType: data.layoutType,
        },
      });

      // 下載檔案
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/pdf' })
      );
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `${date.format('YYYY-MM-DD')} POS 單.pdf`);

      document.body.appendChild(link);

      link.click();
      link.remove();

      return response.data;
    },
    {
      cacheTime: 0,
      enabled: false,
    }
  );
}

export function useCreateLocation() {
  const queryClient = useQueryClient();

  return useMutation(
    ['locations', 'create'],
    (data: {
      name: string;
      idNumber: number;
      backgroundColor: string;
      address: string | null;
      comment: string | null;
      recycleBagsWeight: number;
      city: string | null;
      district: string | null;
    }) => {
      return axios.post('/locations', data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('locations');
        message.success('新增成功');
      },
    }
  );
}

export function useUpdateLocation() {
  const queryClient = useQueryClient();

  return useMutation(
    ['locations', 'update'],
    (data: {
      id: string;
      name: string;
      idNumber: number;
      backgroundColor: string;
      address: string | null;
      comment: string | null;
      recycleBagsWeight: number;
      city: string | null;
      district: string | null;
    }) => {
      return axios.patch(`/locations/${data.id}`, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('locations');
        message.success('更新成功');
      },
    }
  );
}

export function useDeleteLocation() {
  const queryClient = useQueryClient();

  return useMutation(
    ['locations', 'delete'],
    (id: string) => {
      return axios.delete(`/locations/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('locations');
        message.success('刪除成功');
      },
    }
  );
}

export function useProducts(data: {
  skip?: number;
  take?: number;
  providerIds?: string[];
  name?: string;
  isToppings?: boolean;
  state?: ProductControllerGetProductsStateEnum[];
}) {
  return useQuery(['products', data], async () => {
    return (
      await new ProductsApi().productControllerGetProducts(
        data.take,
        data.skip,
        data.providerIds,
        data.name,
        data.isToppings,
        data.state
      )
    ).data;
  });
}

export function useUpdateProduct() {
  const queryClient = useQueryClient();

  return useMutation(
    ['products', 'update'],
    (data: UpdateProductDto & { id: string }) => {
      return new ProductsApi().productControllerUpdateProduct(data.id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['products']);
        message.success('更新成功');
      },
    }
  );
}

export function useDeleteProduct() {
  const queryClient = useQueryClient();

  return useMutation(
    ['products', 'delete'],
    (id: string) => {
      return axios.delete<any, void>(`/products/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('products');
      },
    }
  );
}

export function useRefundOrderItem() {
  const queryClient = useQueryClient();

  return useMutation(
    ['orderItemRefund', 'create'],
    (data: {
      id: string;
      amount: number;
      reason: string;
      note: string;
      fee: number;
    }) => {
      const { id, ...rest } = data;

      return axios.post<any, void>(`/order_items/refund/${data.id}`, rest);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('orders');
        message.success('退款成功');
      },
    }
  );
}

export function useOrderItemRefunds(data: { skip: number; take: number }) {
  return useQuery(['orderItemRefunds', data], async () => {
    const response = await axios.get<{
      refunds: Array<{
        amount: number;
        id: string;
        orderItem: {
          name: string;
          price: number;
          providerName: string;
        };
        note: string | null;
        orderItemId: string;
        reason: REFUND_REASON;
        time: string;
      }>;
      providerFilters: Array<{
        id: string;
        idNumber: string;
        name: string;
      }>;
      timeFilters: string[];
      total: number;
    }>('/order_item_refunds', {
      params: data,
    });

    return response.data;
  });
}

export function useRevokeOrderItemRefund() {
  const queryClient = useQueryClient();

  return useMutation(
    ['orderItemRefund', 'revoke'],
    (id: string) => {
      return axios.delete(`/order_items/revoke_refund/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['orders']);
        message.success('撤銷成功');
      },
    }
  );
}

export function useUpdateOrderItem() {
  const queryClient = useQueryClient();

  return useMutation(
    ['orderItems', 'update'],
    (data: UpdateOrderItemDto & { id: string }) => {
      return new OrderItemsApi().orderItemControllerUpdateOrderItem(
        data.id,
        data
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['orders']);
        message.success('更新成功');
      },
    }
  );
}

export function useProviderEvents(data: { skip: number; take: number }) {
  return useQuery(['providerEvents', data], async () => {
    const response = await axios.get<{
      events: Array<{
        id: string;
        time: string;
        providerId: string;
        type: PROVIDER_EVENT_TYPES;
        fine: number;
        note: string;
        provider: {
          id: string;
          name: string;
          idNumber: string;
        };
      }>;
      timeFilters: string[];
      providerFilters: Array<{
        id: string;
        idNumber: string;
        name: string;
      }>;
      total: number;
    }>('/provider_events', {
      params: data,
    });

    return response.data;
  });
}

export function useUpdateProviderEvent() {
  const queryClient = useQueryClient();

  return useMutation(
    ['providerEvents', 'update'],
    (data: {
      id: string;
      fine: number;
      type: PROVIDER_EVENT_TYPES;
      time: string;
      note: string | null;
    }) => {
      return axios.patch<any, void>(`/provider_events/${data.id}`, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['providerEvents']);
        message.success('更新成功');
      },
    }
  );
}

export function useDeleteProviderEvent() {
  const queryClient = useQueryClient();

  return useMutation(
    ['providerEvents', 'delete'],
    (id: string) => {
      return axios.delete<any, void>(`/provider_events/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['providerEvents']);
        message.success('刪除成功');
      },
    }
  );
}

export function useCreateProviderEvent() {
  const queryClient = useQueryClient();

  return useMutation(
    ['providerEvents', 'create'],
    (data: {
      providerId: string;
      type: PROVIDER_EVENT_TYPES;
      time: string;
      fine: number;
      note: string | null;
    }) => {
      return axios.post<any, void>('/provider_events', data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['providerEvents']);
        message.success('新增成功');
      },
    }
  );
}

export function useExportProviderStatements() {
  const queryClient = useQueryClient();

  return useMutation(
    ['providerStatements'],
    new ProviderStatementsApi().statementControllerExportStatements,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['statements']);
        message.success('匯出成功');
      },
    }
  );
}

export function useExportProducts() {
  return useQuery(
    ['products', 'export'],
    async () => {
      const response = await axios.get('/products/export', {
        responseType: 'arraybuffer',
      });

      // 下載檔案
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/xlsx' })
      );
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `商品資料.xlsx`);

      document.body.appendChild(link);

      link.click();
      link.remove();

      return response.data;
    },
    {
      cacheTime: 0,
      enabled: false,
    }
  );
}

export function useIgnoredToppings() {
  return useQuery(['ignoredToppings'], async () => {
    const response = await axios.get<{
      toppings: string[];
    }>('/provider_statements/ignored_toppings');

    return response.data;
  });
}

export function useUpdateIgnoredToppings() {
  const queryClient = useQueryClient();

  return useMutation(
    ['ignoredToppings', 'update'],
    (data: { toppings: string[] }) => {
      return axios.put<any, void>(
        '/provider_statements/ignored_toppings',
        data
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['ignoredToppings']);
        message.success('更新成功');
      },
    }
  );
}

export function useStatements(data: {
  skip?: number;
  take?: number;
  providerIds?: string[];
  states?: string[];
  timeRange?: string[];
}) {
  const { skip, take, providerIds = [], timeRange = [] } = data || {};

  return useQuery(['statements', data], async () => {
    const response = await axios.get<{
      statements: Array<{
        amount: number;
        createdAt: string;
        detailUrl: string;
        endTime: string;
        id: string;
        note: string;
        receivableTotal: number;
        startTime: string;
        state: STATEMENT_STATE;
        statementUrl: string;
        stateLog: Array<{
          state: STATEMENT_STATE;
          time: string;
          note: string | null;
        }>;
        provider: {
          id: string;
          name: string;
          idNumber: string;
        };
        itemAmount: number; // 主項金額
        toppingAmount: number; // 加項金額
        refundAmount: number; // 退款金額
        commissionAmount: number; // 抽成金額
        fineAmount: number; // 罰款金額
      }>;
      providerFilters: Array<{
        id: string;
        idNumber: string;
        name: string;
      }>;
      timeFilters: Array<Array<string>>;
      total: number;
    }>('/provider_statements', {
      params: {
        skip,
        take,
        providerIds,
        states: data.states,
        timeRange,
      },
    });

    return response.data;
  });
}

export function useUpdateStatement() {
  const queryClient = useQueryClient();

  return useMutation(
    ['statements', 'update'],
    (data: { id: string; note: string | null }) => {
      return axios.patch<any, void>(`/provider_statements/${data.id}`, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['statements']);
        message.success('更新成功');
      },
    }
  );
}

export function useCancelStatement() {
  const queryClient = useQueryClient();

  return useMutation(
    ['statements', 'cancel'],
    (data: { id: string; note: string | null }) => {
      return axios.patch<any, void>(`/provider_statements/${data.id}/cancel`, {
        note: data.note,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['statements']);
        message.success('作廢成功');
      },
    }
  );
}

export function useDeleteStatement() {
  const queryClient = useQueryClient();

  return useMutation(
    ['statements', 'delete'],
    (id: string) => {
      return axios.delete<any, void>(`/provider_statements/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['statements']);
        message.success('刪除成功');
      },
    }
  );
}

export function usePayStatements() {
  const queryClient = useQueryClient();

  return useMutation(
    ['statements', 'pay'],
    (ids: Key[]) => {
      return axios.patch('/provider_statements/pay', {
        ids,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['statements']);
        message.success('付款成功');
      },
    }
  );
}

export function useSendConfirmNotification() {
  const queryClient = useQueryClient();

  return useMutation(
    ['statements', 'sendConfirmNotification'],
    (ids: Key[]) => {
      return axios.patch('/provider_statements/send_confirm_notification', {
        ids,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['statements']);
        message.success('發送成功');
      },
    }
  );
}

export function useDownloadStatementsDetail(data: Key[] = []) {
  return useQuery(
    ['statements', 'downloadStatementsDetail', data],
    async () => {
      if (data.length === 0) return;

      const response = await axios.get('/provider_statements/pending_detail', {
        responseType: 'arraybuffer',
        params: {
          ids: data,
        },
      });

      // 下載檔案
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/xlsx' })
      );
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `待匯款明細.xlsx`);

      document.body.appendChild(link);

      link.click();
      link.remove();

      return response.data;
    },
    {
      cacheTime: 0,
    }
  );
}

export function useUsers(
  data: {
    skip?: number;
    take?: number;
    keyword?: string;
    state?: ['ACTIVATED' | 'REVIEWING' | 'SUSPENDED'] | undefined;
  } = {}
) {
  return useQuery(
    ['users', data],
    async () =>
      (
        await new UsersApi().userControllerFindUsers(
          data.skip,
          data.take,
          data.state,
          data.keyword
        )
      ).data
  );
}

export function useApproveUser() {
  const queryClient = useQueryClient();

  return useMutation(
    ['users', 'approve'],
    (id: string) => {
      return new UsersApi().userControllerApproveUser(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
        message.success('啟用成功');
      },
    }
  );
}

export function useListUsers() {
  return useQuery(['users'], async () => {
    const response = await axios.get<{
      users: Array<{
        id: string;
        displayName: string;
        username: string;
      }>;
    }>('/users/list');

    return response.data;
  });
}

export function useSuspendUser() {
  const queryClient = useQueryClient();

  return useMutation(
    ['users', 'suspend'],
    (id: string) => {
      return axios.patch(`/users/${id}/suspend`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
        message.success('停權成功');
      },
    }
  );
}

export function useUnsuspendUser() {
  const queryClient = useQueryClient();

  return useMutation(
    ['users', 'unsuspend'],
    (id: string) => {
      return axios.patch(`/users/${id}/unsuspend`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
        message.success('解除停權成功');
      },
    }
  );
}

export function useUpdateUser() {
  const queryClient = useQueryClient();

  return useMutation(
    ['users', 'update'],
    (data: UpdateUserDto & { id: string }) => {
      return new UsersApi().userControllerUpdateUser(data.id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
        message.success('更新成功');
      },
    }
  );
}

export function useListRoles() {
  return useQuery('roles', async () => {
    const response = await axios.get<{
      roles: {
        id: string;
        name: string;
      }[];
    }>('/roles/list');

    return response.data;
  });
}

export function useRoles(
  data: {
    skip?: number;
    take?: number;
  } = {}
) {
  return useQuery(['roles', data], async () => {
    const response = await axios.get<{
      roles: Array<{
        id: string;
        description: string;
        name: string;
        isProtected: boolean;
        permissions: Array<{
          id: string;
          name: string;
        }>;
        users: Array<{
          id: string;
          displayName: string;
          username: string;
        }>;
      }>;
      total: number;
    }>('/roles');

    return response.data;
  });
}

export function useCreateRole() {
  const queryClient = useQueryClient();

  return useMutation(
    ['roles', 'create'],
    (data: {
      name: string;
      desc: string;
      userIds: Array<string> | undefined;
      permissionIds: Array<string> | undefined;
    }) => {
      return axios.post('/roles', data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('roles');
        message.success('新增成功');
      },
    }
  );
}

export function useDeleteRole() {
  const queryClient = useQueryClient();

  return useMutation(
    ['roles', 'delete'],
    (id: string) => {
      return axios.delete(`/roles/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('roles');
      },
    }
  );
}

export function useUpdateRole() {
  const queryClient = useQueryClient();

  return useMutation(
    ['roles', 'update'],
    (data: {
      id: string;
      name: string;
      desc: string;
      userIds: Array<string> | undefined;
      permissionIds: Array<string> | undefined;
    }) => {
      return axios.patch(`/roles/${data.id}`, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('roles');
        message.success('更新成功');
      },
    }
  );
}

export function usePermissions() {
  return useQuery(['permissions'], async () => {
    const response = await axios.get<{
      permissions: Array<{
        id: string;
        name: string;
        desc: string;
      }>;
      total: number;
    }>('/permissions');

    return response.data;
  });
}

export function useListPermissions() {
  return useQuery(['permissions'], async () => {
    const response = await axios.get<{
      permissions: Array<{
        id: string;
        name: string;
        displayName: string;
      }>;
    }>('/permissions/list');

    return response.data;
  });
}

export function useLogin() {
  const authStore = useAuthStore();

  return useMutation(
    ['login'],
    (data: { username: string; password: string }) => {
      return axios.post('/auth/login', data);
    },
    {
      onSuccess: (data) => {
        authStore.login(data.data.accessToken);
      },
    }
  );
}

export function useScheduleMeals(data: {
  startTime: number;
  endTime: number;
  locationTagId?: string;
}) {
  const { startTime, endTime, locationTagId } = data || {};

  return useQuery(['scheduleMeals', data], async () => {
    const response = await axios.get<{
      deliveryMeals: Array<{
        id: string;
        time: number;
        provider: schedules.Provider;
        locationTag: schedules.LocationTag;
        driver: schedules.Driver;
        rank: number;
        needToRecycleBags: boolean;
        status:
          | PROVIDER_STATUS_TYPE.PENDING
          | PROVIDER_STATUS_TYPE.CONFIRMED
          | PROVIDER_STATUS_TYPE.REJECTED;
      }>;
    }>('/delivery_meals', {
      params: {
        startTime,
        endTime,
        locationTagId,
      },
    });

    return response.data;
  });
}

export function useScheduleSuggestMeals(data: {
  startTime: number;
  suggsetionsCount?: number;
  groupSize?: number;
}) {
  const { startTime, suggsetionsCount, groupSize } = data || {};

  return useQuery(['scheduleSuggestMeals', data], async () => {
    const response = await axios.get<{
      suggestions: Array<{ providerIds: string[] }>;
    }>('/delivery_meals/suggest-delivery', {
      params: {
        startTime,
        suggsetionsCount,
        groupSize,
      },
    });

    return response.data;
  });
}

export function useUpdateScheduleMeal() {
  const queryClient = useQueryClient();

  return useMutation(
    ['scheduleMeals', 'update'],
    (data: { time: number; locationTagId: string; providerId: string }[]) => {
      return axios.post(`/delivery_meals`, {
        deliveryMeals: data,
      });
    },
    {
      onSuccess: () => {
        message.success('更新成功');
        queryClient.invalidateQueries('scheduleMeals');
        queryClient.invalidateQueries('mealsProviders');
      },
    }
  );
}

export function useDeleteScheduleMeal() {
  const queryClient = useQueryClient();

  return useMutation(
    ['scheduleMeals', 'delete'],
    (data: { time: number; locationTagId: string; providerId: string }[]) => {
      return axios.delete(`/delivery_meals`, {
        data: { deliveryMeals: data },
      });
    },
    {
      onSuccess: () => {
        message.success('刪除成功');
        queryClient.invalidateQueries('scheduleMeals');
        queryClient.invalidateQueries('mealsProviders');
      },
    }
  );
}

export function useUpdateScheduleMealStatus() {
  const queryClient = useQueryClient();

  return useMutation(
    ['scheduleMeals', 'updateStatus'],
    (data: { id: string; status: string }) => {
      return axios.patch(`/delivery_meals/${data.id}/status`, data);
    },
    {
      onSuccess: () => {
        message.success('更新成功');
        queryClient.invalidateQueries('scheduleMeals');
      },
    }
  );
}

export function useUpdateScheduleMealsRank() {
  const queryClient = useQueryClient();

  return useMutation(
    ['scheduleMeals', 'updateRank'],
    (data: { locationTagId: string; time: number; deliveryMealIds: [] }) => {
      return axios.patch(`/delivery_meals/update_ranks`, data);
    },
    {
      onSuccess: () => {
        message.success('更新成功');
        queryClient.invalidateQueries('scheduleMeals');
      },
    }
  );
}

export function useExportDeliveryMeals() {
  return useMutation(
    ['scheduleMeals', 'exportMenus'],
    async (data: { startTime: Dayjs; endTime: Dayjs }) => {
      const response = await axios.get('/delivery_meals/excel-file', {
        params: {
          startTime: data.startTime.valueOf(),
          endTime: data.endTime.valueOf(),
        },
        responseType: 'arraybuffer',
      });

      // 下載檔案
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      );
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute(
        'download',
        `餐點配置_${data.startTime.format('YYYY-MM-DD')}~${data.endTime.format('YYYY-MM-DD')}.xlsx`
      );

      document.body.appendChild(link);

      link.click();
      link.remove();

      return response.data;
    }
  );
}

export function useUpdateScheduleDriver() {
  const queryClient = useQueryClient();

  return useMutation(
    ['scheduleMeals', 'updateDrivers'],
    (data: { id: string; driverId: string }[]) => {
      return axios.patch('/delivery_meals/update_drivers', {
        deliveryMeals: data,
      });
    },
    {
      onSuccess: () => {
        message.success('更新成功');
        queryClient.invalidateQueries('scheduleMeals');
      },
    }
  );
}

export function useResolveStatement() {
  const queryClient = useQueryClient();

  return useMutation(
    ['statements', 'resolve'],
    (data: ResolveStatementDto & { id: string }) => {
      return new ProviderStatementsApi().statementControllerResolveStatement(
        data.id,
        data
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['statements']);
        message.success('已轉為待匯款');
      },
    }
  );
}

export function useDrivers(data?: { skip?: number; take?: number }) {
  return useQuery(['drivers', data], async () => {
    const response = await axios.get<{
      driversInfo: {
        id: string;
        idNumber: string;
        name: string;
        hasLineId: boolean;
        hasLineGroupId: boolean;
        lineGroupId: string;
        address: string;
        phone: string;
        carNumber: string;
        carModel: string;
        carColor: string;
        user: {
          profileId: string;
          id: string;
          bankCode?: string;
          bankAccount?: string;
          bankName?: string;
        };
      }[];
      total: number;
    }>('/driver_info', {
      params: data,
    });

    return response.data;
  });
}

export function useListDrivers(data: { startTime: number; endTime: number }) {
  return useQuery(['drivers', data], async () => {
    const response = await axios.get('/driver_info/list', {
      params: data,
    });

    return response.data;
  });
}

export function useCreateDriver() {
  const queryClient = useQueryClient();

  return useMutation(
    ['drivers', 'create'],
    (data: {
      name: string;
      lineGroupId?: string | null;
      address: string;
      phone: string;
      carNumber: string;
      carModel: string;
      carColor: string;
      bankCode: string | null;
      bankAccount: string | null;
      bankName: string | null;
    }) => {
      return axios.post('/driver_info', data);
    },
    {
      onSuccess: () => {
        message.success('新增成功');
        queryClient.invalidateQueries('drivers');
        queryClient.invalidateQueries('users');
      },
    }
  );
}

export function useUpdateDriver() {
  const queryClient = useQueryClient();

  return useMutation(
    ['drivers', 'update'],
    (data: {
      id: string;
      name: string;
      lineGroupId?: string | null;
      address: string;
      phone: string;
      carNumber: string;
      carModel: string;
      carColor: string;
      bankCode: string | null;
      bankAccount: string | null;
      bankName: string | null;
    }) => {
      return axios.patch(`/driver_info/${data.id}`, data);
    },
    {
      onSuccess: () => {
        message.success('更新成功');
        queryClient.invalidateQueries('drivers');
        queryClient.invalidateQueries('users');
      },
    }
  );
}

export function useDeleteDriver() {
  const queryClient = useQueryClient();

  return useMutation(
    ['drivers', 'delete'],
    (id: string) => {
      return axios.delete(`/driver_info/${id}`);
    },
    {
      onSuccess: () => {
        message.success('刪除成功');
        queryClient.invalidateQueries('drivers');
        queryClient.invalidateQueries('users');
      },
    }
  );
}

export function useDriverLeave(data: {
  startTime: number;
  endTime: number;
  driverIds: Array<string>;
}) {
  return useQuery(['driverLeave', data], async () => {
    const { startTime, endTime, driverIds } = data || {};

    const response = await axios.get<
      [
        {
          driverId: string;
          times: Array<number>;
        },
      ]
    >('/driver_info/leave', {
      params: {
        startTime,
        endTime,
        driverIds,
      },
    });

    return response.data;
  });
}

export function useUpateDriverLeave() {
  const queryClient = useQueryClient();

  return useMutation(
    ['driverLeave', 'update'],
    (
      data: [
        {
          time: number;
          driverId: string;
          isLeave: Boolean;
        },
      ]
    ) => {
      return axios.post(`/driver_info/leave`, {
        driverLeave: data,
      });
    },
    {
      onSuccess: () => {
        message.success('更新成功');
        queryClient.invalidateQueries('driverLeave');
        queryClient.invalidateQueries('drivers');
      },
    }
  );
}

export function useScheduleMealsNote(data: {
  startTime: number;
  endTime: number;
  locationTagId?: string;
}) {
  const { startTime, endTime, locationTagId } = data || {};

  return useQuery(['scheduleMealsNote', data], async () => {
    const response = await axios.get<
      {
        locationTagId: string;
        time: number;
        note: string;
      }[]
    >('/delivery_meals/notes', {
      params: {
        startTime,
        endTime,
        locationTagId,
      },
    });

    return response.data;
  });
}

export function useUpateScheduleMealsNote() {
  const queryClient = useQueryClient();

  return useMutation(
    ['scheduleMealsNote', 'update'],
    (data: { locationTagId: string; time: number; note: string }) => {
      return axios.patch(`/delivery_meals/note`, data);
    },
    {
      onSuccess: () => {
        message.success('更新成功');
        queryClient.invalidateQueries('scheduleMealsNote');
      },
    }
  );
}

export function useMealsProviderColor() {
  return useQuery(['mealsProviderColor'], async () => {
    const response = await axios.get<{ providerId: string; color: string }[]>(
      '/delivery_meals/provider_color/list'
    );

    return response.data;
  });
}

export function useUpateMealsProviderColor() {
  const queryClient = useQueryClient();

  return useMutation(
    ['mealsProviderColor', 'update'],
    (data: { providerId: string; color: string }) => {
      return axios.patch(`/delivery_meals/provider_color`, data);
    },
    {
      onSuccess: () => {
        message.success('更新成功');
        queryClient.invalidateQueries('mealsProviderColor');
      },
    }
  );
}

export function useExportDriverTickets() {
  return useMutation(
    ['scheduleMeals', 'exportDriver'],
    (data: { startTime: number; endTime: number }) => {
      return axios.post('/delivery_meals/export', {
        startTime: data.startTime,
        endTime: data.endTime,
      });
    },
    {
      onSuccess: () => {
        message.success('已開始執行, 請等3 ~ 5分鐘後到資料夾查看');
      },
    }
  );
}

export function useSendDriverTickets() {
  return useMutation(
    ['scheduleMeals', 'sendDriver'],
    (data: { times: number[]; driverIds: string[] }) => {
      return axios.post('/delivery_meals/send-deliveries-to-line', {
        times: data.times,
        driverIds: data.driverIds,
      });
    },
    {
      onSuccess: () => {
        message.success('已開始執行, 請等3 ~ 5分鐘後到資料夾查看');
      },
    }
  );
}

export function useExportDeliveryPerDate() {
  return useMutation(
    ['scheduleMeals', 'exportDeliveryPerDate'],
    (data: { startTime: number; endTime: number }) => {
      return axios.post('/delivery_meals/export-per-date', {
        startTime: data.startTime,
        endTime: data.endTime,
      });
    },
    {
      onSuccess: () => {
        message.success('已開始執行, 請等3 ~ 5分鐘後到資料夾查看');
      },
    }
  );
}

export function useExportDriverSalary() {
  return useMutation(
    ['drivers', 'exportDriverSalary'],
    (data: { startTime: number; endTime: number; driverIds: string[] }) => {
      return axios.post('/driver_info/salary/export', {
        startTime: data.startTime,
        endTime: data.endTime,
        driverIds: data.driverIds,
      });
    },
    {
      onSuccess: () => {
        message.success('已開始執行, 請等3 ~ 5分鐘後到資料夾查看');
      },
    }
  );
}

export function useSendDriverSalary() {
  return useMutation(
    ['drivers', 'sendDriverSalary'],
    (data: { startTime: number; endTime: number; driverIds: string[] }) => {
      return axios.post('/driver_info/salary/send', {
        startTime: data.startTime,
        endTime: data.endTime,
        driverIds: data.driverIds,
      });
    },
    {
      onSuccess: () => {
        message.success('成功發送');
      },
    }
  );
}

export function useMealsListProviders() {
  return useQuery('mealsProviders', async () => {
    const response = await axios.get('/delivery_meals/providers/list');

    return response.data;
  });
}

export function useProviderPublishMessage() {
  return useMutation(
    ['providerPublishMessage'],
    (
      data: {
        providerIds?: Array<string>;
        messages: Array<Message>;
      } = {
        messages: [],
      }
    ) => {
      return axios.postForm('/providers/publish-message', data);
    },
    {
      onSuccess: () => {
        message.success('發送成功');
      },
    }
  );
}

export function useDriversSalary(data: { startTime: number; endTime: number }) {
  return useQuery(
    ['driversSalary', data],
    async () => {
      const response = await axios.get<{
        driversSalary: {
          driver: {
            id: string;
            idNumber: string;
            name: string;
          };
          deliveryDetails: {
            locationTags?: [];
            eventId?: string;
            note?: string;
            amount: number;
            time: number;
          }[];
        }[];
        total: number;
      }>('/driver_info/salary', {
        params: data,
      });

      return response.data;
    },
    { refetchOnMount: true }
  );
}

export function useCreateDriverEvent() {
  const queryClient = useQueryClient();

  return useMutation(
    ['driverEvents', 'create'],
    (data: {
      driverId: string;
      amount: number;
      note: string;
      time: number;
    }) => {
      return axios.post('/driver_info/events', data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('driversSalary');
      },
    }
  );
}

export function useDeleteDriverEvent() {
  const queryClient = useQueryClient();

  return useMutation(
    ['driverEvents', 'delete'],
    (id: string) => {
      return axios.delete(`/driver_info/events/${id}`);
    },
    {
      onSuccess: () => {
        message.success('刪除成功');
        queryClient.invalidateQueries('driversSalary');
      },
    }
  );
}

export function useReceivables(data: {
  skip?: number;
  take?: number;
  providerIds?: Array<string>;
}) {
  return useQuery(['receivables', data], async () => {
    return (
      await new ReceivablesApi().receivableControllerFindReceivables(
        data.take,
        data.skip,
        data.providerIds
      )
    ).data;
  });
}

export function useCreateReceivable() {
  const queryClient = useQueryClient();

  return useMutation(
    ['receivables', 'create'],
    (data: CreateReceivableDto) => {
      return new ReceivablesApi().receivableControllerCreateReceivable(data);
    },
    {
      onSuccess: () => {
        message.success('新增成功');
        queryClient.invalidateQueries('receivables');
      },
    }
  );
}

export function useUpdateReceivable() {
  const queryClient = useQueryClient();

  return useMutation(
    ['receivables', 'update'],
    (data: UpdateReceivableDto & { id: string }) => {
      return new ReceivablesApi().receivableControllerUpdateReceivable(
        data.id,
        data
      );
    },
    {
      onSuccess: () => {
        message.success('更新成功');
        queryClient.invalidateQueries('receivables');
      },
    }
  );
}

export function useDeleteReceivable() {
  const queryClient = useQueryClient();

  return useMutation(
    ['receivables', 'delete'],
    (id: string) => {
      return new ReceivablesApi().receivableControllerDeleteReceivable(id);
    },
    {
      onSuccess: () => {
        message.success('刪除成功');
        queryClient.invalidateQueries('receivables');
      },
    }
  );
}

export function useReceivableNames() {
  return useQuery(['receivables', 'receivableNames'], async () => {
    return (await new ReceivablesApi().receivableControllerGetReceivableNames())
      .data;
  });
}

export function useLineBotDriverGroupLogs() {
  return useQuery('lineBotDriverGroupLogs', async () => {
    const response = await axios.get('/line_bot/driver_group_log');

    return response.data;
  });
}

export function useRecommendationCount(data: {
  startTime: number;
  endTime: number;
}) {
  return useQuery(['recommendUserCount', data], async () => {
    const response = await axios.get('/recommend-user/count', {
      params: {
        startTime: data.startTime,
        endTime: data.endTime,
      },
    });

    return response.data;
  });
}

export function useRecommendationSummary(data: {
  startTime: number;
  endTime: number;
}) {
  return useQuery(['recommendUserSummary', data], async () => {
    const response = await axios.get('/recommend-user/summary', {
      params: {
        startTime: data.startTime,
        endTime: data.endTime,
      },
    });

    return response.data;
  });
}

export function useRecommendationRanking(data: { top: number }) {
  return useQuery(['recommendUserRanking', data], async () => {
    const response = await axios.get('/recommend-user/ranking', {
      params: {
        top: data.top,
      },
    });

    return response.data;
  });
}
