import { TablePaginationConfig } from 'antd';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export function usePagination(
  {
    defaultPageSize = 10,
    syncUrl = false,
  }: {
    defaultPageSize: number;
    syncUrl?: boolean;
  } = {
    defaultPageSize: 10,
    syncUrl: false,
  }
): TablePaginationConfig & { skip: number; take: number } {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get('page')) || 1;
  const pageSize = Number(searchParams.get('pageSize')) || defaultPageSize;
  const [currentPageInternal, setCurrentPage] = useState(page);
  const [pageSizeInternal, setPageSize] = useState(pageSize);
  const updatedParams = new URLSearchParams(searchParams.toString());

  useEffect(() => {
    if (syncUrl) {
      setCurrentPage(page);
      setPageSize(pageSize);
    }
  }, [page, pageSize, syncUrl]);

  return {
    current: currentPageInternal,
    defaultPageSize,
    pageSize: pageSizeInternal,
    pageSizeOptions: ['10', '20', '30', '40', '50', '100', '200'],
    showQuickJumper: true,
    skip: (currentPageInternal - 1) * pageSizeInternal,
    take: pageSizeInternal,
    showTotal: (to, range) => `${range[0]}-${range[1]} of ${to} items`,
    onShowSizeChange: (current, size) => {
      setPageSize(size);

      if (syncUrl) {
        updatedParams.set('pageSize', size.toString());
        setSearchParams(updatedParams);
      }
    },
    onChange: (page) => {
      setCurrentPage(page);
      if (syncUrl) {
        updatedParams.set('page', page.toString());
        setSearchParams(updatedParams);
      }
    },
  };
}
