import moment from 'moment';
import {
  CreateReceivableDto,
  ReceivableDto,
  UpdateReceivableDto,
} from '../../utils/api-client';
import { Button, Table, TableColumnProps } from 'antd';
import useModal from 'antd/es/modal/useModal';
import { ReceivableFormComponent } from './receivable-form.component';
import { useForm } from 'antd/es/form/Form';
import { ColumnFilterItem, FilterValue } from 'antd/es/table/interface';
import {
  RECEIVABLE_STATE_COLOR,
  RECEIVABLE_STATE,
  RECEIVABLE_STATE_LABEL,
} from '../../constants/receivable';

type ReceivablesComponentProps = {
  createReceivable: (data: CreateReceivableDto) => Promise<any>;
  deleteReceivable: (id: string) => Promise<any>;
  isLoading: boolean;
  pagination: any;
  providerFilters: Array<ColumnFilterItem>;
  receivables: Array<ReceivableDto>;
  setFilters: (filters: Record<string, FilterValue | null>) => void;
  updateReceivable: (
    data: UpdateReceivableDto & {
      id: string;
    }
  ) => Promise<any>;
};

export default function ReceivablesComponent(props: ReceivablesComponentProps) {
  const {
    createReceivable,
    deleteReceivable,
    isLoading,
    pagination,
    providerFilters,
    receivables,
    setFilters,
    updateReceivable,
  } = props;
  const [modal, contextHolder] = useModal();
  const [form] = useForm();

  const onCreateButtonClick = () => {
    modal.confirm({
      content: <ReceivableFormComponent form={form} />,
      icon: null,
      okText: '新增',
      title: '新增費用',
      onOk: async () => {
        const values = await form.getFieldsValue();

        return createReceivable({
          amount: values.amount,
          dueTime: values.dueTime,
          name: values.name,
          note: values.note,
          providerId: values.providerId,
        });
      },
    });
  };

  const onDeleteButtonClick = (record: ReceivableDto) => {
    modal.confirm({
      title: '刪除費用',
      icon: null,
      content: `確定要刪除費用 ${record.name} 嗎？`,
      onOk: () => {
        return deleteReceivable(record.id);
      },
    });
  };

  const onEditButtonClick = (record: ReceivableDto) => {
    modal.confirm({
      icon: null,
      okText: '儲存',
      title: '編輯費用',
      content: (
        <ReceivableFormComponent
          form={form}
          data={{
            ...record,
            providerId: record.provider.id,
          }}
        />
      ),
      onOk: async () => {
        const values = await form.getFieldsValue();

        return updateReceivable({
          amount: values.amount,
          dueTime: values.dueTime,
          id: record.id,
          name: values.name,
          note: values.note,
        });
      },
    });
  };

  const columns: Array<TableColumnProps<ReceivableDto>> = [
    {
      width: 80,
      align: 'center',
      title: '狀態',
      dataIndex: 'state',
      render(value) {
        return (
          <span
            className={`text-${RECEIVABLE_STATE_COLOR[value as RECEIVABLE_STATE].toLowerCase()}-500`}
          >
            {RECEIVABLE_STATE_LABEL[value as RECEIVABLE_STATE]}
          </span>
        );
      },
    },
    {
      align: 'center',
      dataIndex: 'dueTime',
      title: '應收日期',
      width: 100,
      render: (value) => moment(value).format('YYYY/MM/DD'),
    },
    {
      title: '名稱',
      dataIndex: 'name',
    },
    {
      title: '供應商名稱',
      width: 200,
      dataIndex: 'providerIds',
      filters: providerFilters,
      render: (value, record) => {
        const { provider: { idNumber, name } = {} } = record;
        return `${idNumber} - ${name}`;
      },
    },
    {
      title: '金額',
      width: 200,
      dataIndex: 'amount',
      render: (value) =>
        value.toLocaleString({
          style: 'currency',
          currency: 'TWD',
        }) + '元',
    },
    {
      title: '備註',
      dataIndex: 'note',
    },
    {
      title: '操作',
      width: 120,
      render: (value, record) => (
        <div className="flex gap-2">
          <Button
            type="primary"
            size="small"
            onClick={() => onEditButtonClick(record)}
          >
            編輯
          </Button>
          <Button
            type="primary"
            size="small"
            danger
            onClick={() => {
              onDeleteButtonClick(record);
            }}
          >
            刪除
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      {contextHolder}
      <span className="text-2xl font-bold">收款管理</span>
      <Table
        columns={columns}
        dataSource={receivables}
        loading={isLoading}
        pagination={pagination}
        rowKey="id"
        size="small"
        onChange={(_pagination, filters) => {
          setFilters(filters);
        }}
        title={() => (
          <Button type="primary" onClick={onCreateButtonClick}>
            新增費用
          </Button>
        )}
      />
    </div>
  );
}
