import { ReactNode, useMemo } from 'react';
import useModal from 'antd/es/modal/useModal';
import { useForm } from 'antd/es/form/Form';
import { Space, Badge, Flex, Table, Row, Col, Button, Typography } from 'antd';
import { PresetStatusColorType } from 'antd/es/_util/colors';
import { TruckOutlined } from '@ant-design/icons';
import type { Dayjs } from 'dayjs';
import { isEmpty, map } from 'lodash';

import { PROVIDER_STATUS_MAPPING } from '../../constants/schedule-meals.types';
import schedules from '../schedules';
import { ColumnType } from 'antd/es/table';

import { ExportDriverTicketFormComponent } from './export-driver-ticket-form.component';
import { ExportDeliveryPerDateComponent } from './export-delivery-per-date-form.component';
import { SendDriverTicketFormComponent } from './send-driver-ticket-form.component';

const WEEK_TRANSLATE = ['日', '一', '二', '三', '四', '五', '六'];

type ScheduleOverviewComponentProps = {
  listData: {
    name: string;
    leaveTimes: number[];
    data: { timestamp: number; list: schedules.CellData[] }[];
  }[];
  timestampList: Dayjs[];
  isLoading: boolean;
  setSelectDate: Function;
  driverList: {
    label: string;
    value: string;
  }[];
  exportDriverTickets: (data: { startTime: number; endTime: number }) => void;
  sendDriverTickets: (data: { times: number[]; driverIds: string[] }) => void;
  exportDeliveryPerDate: (data: { startTime: number; endTime: number }) => void;
};

const BASIC_COLUMNS: ColumnType<
  ScheduleOverviewComponentProps['listData'][number]
>[] = [
  {
    key: 'idNumber',
    dataIndex: 'idNumber',
    width: 70,
    title: '代號',
    fixed: 'left',
  },
  {
    key: 'name',
    dataIndex: 'name',
    width: 100,
    title: '名稱',
    fixed: 'left',
  },
  {
    title: '車牌',
    dataIndex: 'carNumber',
  },
  {
    title: '車型',
    dataIndex: 'carModel',
  },
  {
    title: '電話',
    dataIndex: 'phone',
  },
];

export function ScheduleOverviewComponent(
  props: ScheduleOverviewComponentProps
) {
  const [modal, contextHolder] = useModal();
  const [exportDriverTicketForm] = useForm<{ dateRange: [Dayjs, Dayjs] }>();
  const [sendDriverTicketForm] = useForm<{
    times: Dayjs[];
    driverIds: string[];
  }>();

  const onSendDriverTicketClick = () => {
    modal.confirm({
      icon: null,
      title: '發送司機票據',
      content: (
        <SendDriverTicketFormComponent
          form={sendDriverTicketForm}
          driverOptions={props.driverList}
        />
      ),
      okText: '確定',
      cancelText: '取消',
      onOk: async () => {
        const values = await sendDriverTicketForm.validateFields();
        await props.sendDriverTickets({
          times: map(values.times, (time) => time.startOf('day').valueOf()),
          driverIds: values.driverIds || [],
        });
      },
    });
  };

  const onExportDriverTicketClick = () => {
    modal.confirm({
      icon: null,
      title: '匯出司機票據',
      content: (
        <ExportDriverTicketFormComponent
          dateRange={[props.timestampList[0], props.timestampList[4]]}
          form={exportDriverTicketForm}
        />
      ),
      okText: '確定',
      cancelText: '取消',
      onOk: async () => {
        const values = await exportDriverTicketForm.validateFields();
        const [start, end] = values.dateRange;
        await props.exportDriverTickets({
          startTime: start.valueOf(),
          endTime: end.valueOf(),
        });
      },
    });
  };

  const onExportDeliveryPerDateClick = () => {
    modal.confirm({
      icon: null,
      title: '匯出地基主筆記',
      content: (
        <ExportDeliveryPerDateComponent
          dateRange={[props.timestampList[0], props.timestampList[4]]}
          form={exportDriverTicketForm}
        />
      ),
      okText: '確定',
      cancelText: '取消',
      onOk: async () => {
        const values = await exportDriverTicketForm.validateFields();
        const [start, end] = values.dateRange;
        await props.exportDeliveryPerDate({
          startTime: start.valueOf(),
          endTime: end.valueOf(),
        });
      },
    });
  };

  const columns = useMemo(() => {
    const dynamicColumns: ColumnType<
      ScheduleOverviewComponentProps['listData'][number]
    >[] = map(props.timestampList, (item, index) => {
      const date: string = item.format('YYYY-MM-DD');

      return {
        key: date,
        dataIndex: date,
        width: 250,
        title: `${date} (${WEEK_TRANSLATE[item.get('day')]})`,
        render: (
          _value: any,
          record: {
            leaveTimes: number[];
            data: {
              list: schedules.CellData[];
            }[];
          }
        ) =>
          renderCell(
            record.data[index]?.list || [],
            record.leaveTimes.indexOf(item.valueOf()) !== -1
          ),
      };
    });

    return BASIC_COLUMNS.concat(dynamicColumns);
  }, [props.timestampList]);

  return (
    <Space direction="vertical" className="w-full page-schedule-meals">
      {contextHolder}
      <div className="text-2xl font-bold">班表總覽</div>

      <CalendarHeader
        value={props.timestampList[0]}
        onChange={(date) => {
          props.setSelectDate(date);
        }}
        onExportDriverTicketClick={onExportDriverTicketClick}
        onSendDriverTicketClick={onSendDriverTicketClick}
        onExportDeliveryPerDateClick={onExportDeliveryPerDateClick}
      />

      <Table
        rowKey="id"
        dataSource={props.listData}
        loading={props.isLoading}
        columns={columns}
        scroll={{ x: 'max-content' }}
        size="small"
        pagination={false}
      />
    </Space>
  );
}

const renderCell = (
  list: schedules.CellData[],
  isLeave: Boolean
): ReactNode => {
  if (isLeave) {
    return <Typography.Text type="secondary">（請假）</Typography.Text>;
  }

  return (
    <ul style={{ padding: 0 }}>
      {list.map(
        (item: schedules.CellData) =>
          !isEmpty(item) && (
            <Flex vertical key={`${item.locationTag.id}.${item.provider.id}`}>
              <Flex component="li" gap="small">
                <Badge
                  status={
                    item?.status
                      ? (PROVIDER_STATUS_MAPPING[
                          item.status
                        ] as PresetStatusColorType)
                      : 'default'
                  }
                  text={
                    <>
                      <span
                        className="p-1 block"
                        style={{
                          backgroundColor: item.needToRecycleBags
                            ? '#ffe58f'
                            : 'unset',
                        }}
                      >
                        {`${item.provider?.type}-${item.provider?.name}`} (
                        {item.provider?.address})
                      </span>
                      <Row justify="end">
                        <TruckOutlined style={{ margin: 5 }} />
                        {item.locationTag.name}
                      </Row>
                    </>
                  }
                />
              </Flex>
            </Flex>
          )
      )}
    </ul>
  );
};

type CalendarHeaderProps = {
  value: Dayjs;
  onChange(date: Dayjs): void;
  onExportDriverTicketClick: () => void;
  onSendDriverTicketClick: () => void;
  onExportDeliveryPerDateClick: () => void;
};

const CalendarHeader = ({
  value,
  onChange,
  onExportDriverTicketClick,
  onSendDriverTicketClick,
  onExportDeliveryPerDateClick,
}: CalendarHeaderProps) => {
  return (
    <Space align="baseline">
      <Row gutter={8} align="middle">
        <Col>
          <Button
            size="small"
            onClick={() => {
              onChange(value.subtract(7, 'days'));
            }}
          >
            上一週
          </Button>
        </Col>
        <Col>
          <Button
            size="small"
            onClick={() => {
              onChange(value.add(7, 'days'));
            }}
          >
            下一週
          </Button>
        </Col>
        <Col>
          <Button type="primary" onClick={onExportDriverTicketClick}>
            匯出司機單據
          </Button>
        </Col>
        <Col>
          <Button type="primary" onClick={onSendDriverTicketClick}>
            發送司機單據
          </Button>
        </Col>
        <Col>
          <Button type="primary" onClick={onExportDeliveryPerDateClick}>
            匯出地基主筆記
          </Button>
        </Col>
      </Row>
    </Space>
  );
};
