import { Button, Space, Table, TableProps } from 'antd';
import useModal from 'antd/es/modal/useModal';
import { ProviderTypeFormComponent } from './provider-type-form';
import { useRef } from 'react';
import { FormInstance } from 'antd/lib';
import { ProviderType } from '../../utils/api-client';
import { ColorFactory } from 'antd/es/color-picker/color';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

type ProviderTypesComponentProps = {
  data: ProviderType[];
  isLoading: boolean;
  createProviderType: (data: { name: string; color: string }) => any;
  deleteProviderType: (id: string) => any;
  updateProviderType: (data: {
    id: string;
    name: string;
    color: string;
  }) => any;
};

export const ProviderTypesComponent = (props: ProviderTypesComponentProps) => {
  const { data, isLoading, updateProviderType } = props;
  const [modal, contextHolder] = useModal();
  const formRef = useRef<
    FormInstance<{
      name: string;
      color: ColorFactory;
    }>
  >();

  const columns: TableProps<
    ProviderTypesComponentProps['data'][number]
  >['columns'] = [
    {
      align: 'center',
      title: '顏色',
      width: 60,
      dataIndex: 'color',
      render: (color) => (
        <div className="flex items-center justify-center">
          <div
            className="inline-block w-6 h-6 rounded-full"
            style={{ backgroundColor: color }}
          />
        </div>
      ),
    },
    {
      title: '類型',
      dataIndex: 'name',
    },
    {
      title: '操作',
      width: 40,
      render: (record) => {
        return (
          <Space>
            <Button
              icon={<EditOutlined />}
              shape="circle"
              onClick={() => onEditButtonClick(record)}
              size="small"
            />
            <Button
              size="small"
              danger
              icon={<DeleteOutlined />}
              onClick={() => onDeleteButtonClick(record)}
              shape="circle"
            />
          </Space>
        );
      },
    },
  ];

  const onCreateButtonClick = () => {
    modal.confirm({
      title: '新增供應商類型',
      icon: null,
      content: <ProviderTypeFormComponent formRef={formRef} />,
      onOk: async () => {
        const values = await formRef.current?.validateFields();

        return props.createProviderType({
          name: values!.name,
          color: values!.color.toHexString(),
        });
      },
    });
  };

  const onEditButtonClick = (
    record: ProviderTypesComponentProps['data'][number]
  ) => {
    modal.confirm({
      title: '編輯供應商類型',
      icon: null,
      onOk: async () => {
        const values = await formRef.current?.validateFields();

        return updateProviderType({
          id: record.name,
          name: values!.name,
          color: values!.color.toHexString(),
        });
      },
      content: <ProviderTypeFormComponent data={record} formRef={formRef} />,
    });
  };

  const onDeleteButtonClick = (
    record: ProviderTypesComponentProps['data'][number]
  ) => {
    modal.confirm({
      title: '刪除供應商類型',
      content: (
        <Space direction="vertical">
          <div>確定要刪除 {record.name} 嗎？</div>
          <div>刪除後無法復原</div>
        </Space>
      ),
      onOk: () => {
        return props.deleteProviderType(record.name);
      },
    });
  };

  return (
    <Space direction="vertical" className="w-full">
      {contextHolder}
      <div className="text-2xl font-bold">供應商類型列表</div>
      <Table
        title={() => (
          <Button type="primary" onClick={onCreateButtonClick}>
            新增
          </Button>
        )}
        columns={columns}
        dataSource={data}
        loading={isLoading}
        rowKey="name"
        size="small"
      />
    </Space>
  );
};
