import {
  Button,
  Checkbox,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Radio,
} from 'antd';
import { useOutletContext } from 'react-router-dom';
import { FindProvider } from '../../utils/api-client';
import { useEffect, useImperativeHandle } from 'react';
import FormItem from 'antd/es/form/FormItem';
import { every, map, times } from 'lodash';
import {
  COMMERCIAL_TERMS,
  GAMBLING_DISCOUNT_TYPE,
  GAMBLING_DISCOUNT_TYPE_OPTIONS,
} from '../../constants/provider-form.types';
import ErrorList from 'antd/es/form/ErrorList';
import FormList from 'antd/es/form/FormList';
import { useForm, useWatch } from 'antd/es/form/Form';
import { useUpdateProvider } from '../../utils/api.hook';

export default function ProviderBusinessInfoComponent() {
  const [form] = useForm();
  const { formRef, provider } = useOutletContext<{
    formRef: React.RefObject<FormInstance>;
    provider: FindProvider;
  }>();
  useImperativeHandle(
    formRef,
    () => {
      return form;
    },
    [form]
  );
  const { mutateAsync: updateProvider } = useUpdateProvider();
  const commercialTerms = useWatch(['businessInfo', 'commercialTerms'], form);

  useEffect(() => {
    form.resetFields();
  }, [form, provider]);

  return (
    <Form
      form={form}
      autoComplete="off"
      preserve={false}
      initialValues={provider}
      onFinish={(values) => {
        return updateProvider(values);
      }}
    >
      <FormItem
        name="id"
        label="ID"
        hidden
        rules={[
          {
            required: true,
            message: '請輸入ID',
          },
        ]}
      >
        <Input />
      </FormItem>
      <FormItem
        name={['businessInfo', 'chargeSlottingFee']}
        label="是否收取上架費"
        valuePropName="checked"
      >
        <Checkbox />
      </FormItem>
      <FormItem name={['businessInfo', 'commercialTerms']} label="商業條件">
        <Radio.Group
          options={[
            { label: '買斷', value: COMMERCIAL_TERMS.BUYOUT },
            { label: '抽成', value: COMMERCIAL_TERMS.COMMISSION },
            { label: '對賭', value: COMMERCIAL_TERMS.GAMBLING },
          ]}
        />
      </FormItem>
      {commercialTerms === COMMERCIAL_TERMS.COMMISSION && (
        <div className="flex gap-2">
          <FormItem
            name={['businessInfo', 'commission']}
            label="抽成"
            rules={[
              {
                required: true,
                message: '請輸入抽成',
              },
              {
                type: 'number',
                min: 0,
                max: 100,
                message: '抽成必須介於 0 ~ 100',
              },
            ]}
          >
            <InputNumber step={1} min={0} max={100} />
          </FormItem>
          <div className="mt-1">%</div>
        </div>
      )}
      {commercialTerms === COMMERCIAL_TERMS.GAMBLING && (
        <div className="pl-4">
          <FormList
            name={['businessInfo', 'gamblingRules']}
            rules={[
              {
                validator: async (_, names) => {
                  if (!names || names.length < 1) {
                    return Promise.reject(new Error('至少需要一個對賭條件'));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                <div className="flex flex-col items-stretch divide-y divide-solid">
                  {map(fields, ({ key, name, ...restField }) => {
                    return (
                      <div key={key} className="flex flex-col pt-4 border-x-0">
                        <div className="flex items-center mb-2 gap-4">
                          <div className="text-lg font-bold">
                            條件 {name + 1}
                          </div>
                          <Button
                            type="primary"
                            danger
                            onClick={() => remove(name)}
                            size="small"
                          >
                            刪除
                          </Button>
                        </div>
                        <FormItem
                          {...restField}
                          name={[name, 'itemSoldQuantityGuarantee']}
                          label="保證販售數量"
                          rules={[
                            {
                              required: true,
                              message: '請輸入保證販售數量',
                            },
                            {
                              type: 'integer',
                              min: 0,
                              message: '保證販售數量必須大於 0',
                            },
                            {
                              async validator(record, value) {
                                const gamblingRules =
                                  formRef.current?.getFieldValue([
                                    'businessInfo',
                                    'gamblingRules',
                                  ]);

                                if (
                                  every(
                                    times(name, (n) => {
                                      return (
                                        gamblingRules[n]
                                          ?.itemSoldQuantityGuarantee < value
                                      );
                                    })
                                  )
                                ) {
                                  return true;
                                }

                                return Promise.reject(
                                  new Error('保證販售數量必須大於前一條件')
                                );
                              },
                            },
                          ]}
                        >
                          <InputNumber min={1} step={1} />
                        </FormItem>
                        <div className="flex items-center gap-2">
                          <FormItem
                            name={[name, 'discountType']}
                            label="商業條件類型"
                            rules={[
                              {
                                required: true,
                                message: '請選擇商業條件類型',
                              },
                            ]}
                          >
                            <Radio.Group
                              options={GAMBLING_DISCOUNT_TYPE_OPTIONS}
                            />
                          </FormItem>
                          <FormItem
                            shouldUpdate={(prevValues, curValues) => {
                              return (
                                prevValues.businessInfo.gamblingRules[name]
                                  ?.discountType !==
                                curValues.businessInfo.gamblingRules[name]
                                  ?.discountType
                              );
                            }}
                            noStyle
                          >
                            {({ getFieldValue }) => {
                              const discountType = getFieldValue([
                                'businessInfo',
                                'gamblingRules',
                                name,
                                'discountType',
                              ]);

                              return (
                                discountType && (
                                  <div className="flex gap-2">
                                    <FormItem
                                      {...restField}
                                      label={
                                        discountType ===
                                        GAMBLING_DISCOUNT_TYPE.PERCENTAGE
                                          ? '百分比'
                                          : '折扣'
                                      }
                                      name={[name, 'discountAmount']}
                                      rules={[
                                        {
                                          required: true,
                                          message: '請輸入對賭',
                                        },
                                      ]}
                                    >
                                      <InputNumber step={1} min={0} max={100} />
                                    </FormItem>
                                    <div className="mt-1">
                                      {discountType ===
                                      GAMBLING_DISCOUNT_TYPE.PERCENTAGE
                                        ? '% OFF'
                                        : '元'}
                                    </div>
                                  </div>
                                )
                              );
                            }}
                          </FormItem>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => add()}
                  className="mb-4"
                >
                  新增對賭條件
                </Button>
                <ErrorList errors={errors} className="text-red-400" />
              </>
            )}
          </FormList>
        </div>
      )}
      {/* <div className="mb-4 text-lg font-bold">帳務資料 - 付款</div>
      <FormItem name={['paymentInfo', 'receiptType']} label="收據類型">
        <Radio.Group
          options={[
            { label: '發票', value: INVOICE_TYPE.INVOICE },
            { label: '收據', value: INVOICE_TYPE.RECEIPT },
          ]}
        />
      </FormItem>
      <FormItem name={['paymentInfo', 'account']} label="匯款帳號">
        <Input />
      </FormItem>
      <FormItem name={['paymentInfo', 'accountName']} label="匯款戶名">
        <Input />
      </FormItem>
      <FormItem name={['paymentInfo', 'bankName']} label="匯款銀行名稱">
        <Input />
      </FormItem>
      <FormItem name={['paymentInfo', 'bankBranchName']} label="匯款分行名稱">
        <Input />
      </FormItem>
      <FormItem
        name={['paymentInfo', 'shouldInvoice']}
        label="需回開發票"
        valuePropName="checked"
      >
        <Checkbox />
      </FormItem> */}
    </Form>
  );
}
