import {
  useCreateProviderType,
  useDeleteProviderType,
  useProviderTypes,
  useUpdateProviderType,
} from '../../utils/api-hooks/provider.hook';
import { ProviderTypesComponent } from './provider-types-component';

export function ProviderTypesContainer() {
  const { data: { data } = {}, isLoading } = useProviderTypes();
  const { mutateAsync: createProviderType, isLoading: isCreatingProviderType } =
    useCreateProviderType();
  const { mutateAsync: deleteProviderType, isLoading: isDeletingProviderType } =
    useDeleteProviderType();
  const { mutateAsync: updateProviderType, isLoading: isUpdatingProviderType } =
    useUpdateProviderType();

  return (
    <ProviderTypesComponent
      createProviderType={createProviderType}
      data={data || []}
      deleteProviderType={deleteProviderType}
      updateProviderType={updateProviderType}
      isLoading={
        isLoading ||
        isCreatingProviderType ||
        isDeletingProviderType ||
        isUpdatingProviderType
      }
    />
  );
}
