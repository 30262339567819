import { AutoComplete, DatePicker, Form, Input, InputNumber } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib';
import { Ref, useImperativeHandle } from 'react';
import { CreateReceivablePlanDto } from '../../utils/api-client';
import FormItem from 'antd/es/form/FormItem';
import { useReceivableNames } from '../../utils/api.hook';
import { map } from 'lodash';
import { ProviderSelectorContainer } from '../provider-selector/provider-selector.containers';

type InstallmentFormProps = {
  formRef: Ref<FormInstance<CreateReceivablePlanDto>>;
  hideProviderSelector?: boolean;
};

export default function InstallmentFormComponent(props: InstallmentFormProps) {
  const [form] = useForm<CreateReceivablePlanDto>();
  const { data: { names: receivableNames = [] } = {} } = useReceivableNames();

  useImperativeHandle(props.formRef, () => form);

  return (
    <Form form={form} autoComplete="off">
      {!props.hideProviderSelector && <ProviderSelectorContainer isRequired />}
      <FormItem
        name="name"
        label="名稱"
        rules={[{ required: true, message: '請輸入名稱' }]}
      >
        <AutoComplete
          options={map(receivableNames, (name) => ({
            value: name,
          }))}
        />
      </FormItem>
      <FormItem
        label="首期日期"
        name="startTime"
        rules={[{ required: true, message: '請輸入首期日期' }]}
      >
        <DatePicker />
      </FormItem>
      <FormItem
        label="金額"
        name="totalAmount"
        rules={[{ required: true, message: '請輸入金額' }]}
      >
        <InputNumber min={1} />
      </FormItem>
      <FormItem
        label="期數"
        name="installmentAmount"
        rules={[{ required: true, message: '請輸入期數' }]}
      >
        <InputNumber min={1} />
      </FormItem>
      <FormItem
        label="每期間隔（天）"
        name="durationDays"
        rules={[{ required: true, message: '請輸入每期間隔' }]}
      >
        <InputNumber min={1} />
      </FormItem>
      <FormItem label="備註" name="note">
        <Input.TextArea />
      </FormItem>
    </Form>
  );
}
