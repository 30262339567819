import { Button, Form, Input, Table, TableProps, Tooltip } from 'antd';
import {
  CreateReceivablePlanDto,
  ReceivablePlan,
  UpdateReceivablePlanDto,
} from '../../utils/api-client';
import moment from 'moment';
import FormItem from 'antd/es/form/FormItem';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  useUpdateReceivablePlan,
  useDeleteReceivablePlan,
  useCreateReceivablePlan,
  useReceivablePlans,
} from '../../utils/api-hooks/receivable-plan.hook';
import { FormInstance, useForm } from 'antd/es/form/Form';
import useModal from 'antd/es/modal/useModal';
import { useRef } from 'react';
import { ReceivablePlanReceivablesComponent } from '../../providers/provider/receivable-plan-receivables.component';
import { usePagination } from '../usePagination';
import InstallmentFormComponent from './installments-form.component';
import { Link } from 'react-router-dom';

type InstallmentsTableComponentProps = {
  providerId?: string;
};

export default function InstallmentsTableComponent(
  props: InstallmentsTableComponentProps
) {
  const { providerId } = props;
  const { mutateAsync: createReceivablePlan } = useCreateReceivablePlan();
  const { mutateAsync: updateReceivablePlan } = useUpdateReceivablePlan();
  const { mutateAsync: deleteReceivablePlan } = useDeleteReceivablePlan();

  const pagination = usePagination();
  const { data: { data, total } = {}, isLoading } = useReceivablePlans({
    providerId,
    skip: pagination.skip,
    take: pagination.take,
  });

  const [modal, contextHolder] = useModal();
  const formRef = useRef<FormInstance<CreateReceivablePlanDto>>(null);
  const [editForm] = useForm<UpdateReceivablePlanDto>();

  const onCreateButtonClick = () => {
    modal.confirm({
      title: '新增分期收款設定',
      icon: null,
      content: (
        <InstallmentFormComponent
          formRef={formRef}
          hideProviderSelector={!!providerId}
        />
      ),
      onOk: () => {
        const values = formRef.current?.getFieldsValue();

        if (!values) {
          return Promise.reject();
        }

        const pid = providerId || values.providerId;

        if (!pid) {
          return Promise.reject();
        }

        return createReceivablePlan({
          ...values,
          providerId: pid,
        });
      },
    });
  };

  const columns: TableProps<ReceivablePlan>['columns'] = [
    {
      width: 120,
      align: 'center',
      title: '首期應收日期',
      dataIndex: 'startTime',
      render(value) {
        return moment(value).utcOffset(8).format('YYYY/MM/DD');
      },
    },
    {
      width: 200,
      title: '供應商',
      dataIndex: 'provider',
      hidden: !!providerId,
      render(value, record) {
        return (
          <Link to={`/providers/${record.provider.id}/receivable-info`}>
            {record.provider.idNumber} - {record.provider.name}
          </Link>
        );
      },
    },
    {
      dataIndex: 'name',
      title: '名稱',
      width: 150,
    },
    {
      dataIndex: 'totalAmount',
      title: '金額',
      width: 150,
      render: (value) => {
        return (
          value.toLocaleString({
            style: 'currency',
            currency: 'TWD',
            maximumFractionDigits: 0,
          }) + '元'
        );
      },
    },
    {
      width: 80,
      align: 'center',
      title: '期數',
      dataIndex: 'installmentAmount',
      render(value) {
        return value + '期';
      },
    },
    {
      dataIndex: 'durationDays',
      title: '每期間隔',
      width: 120,
      render(value) {
        return value + '天';
      },
    },
    {
      title: '備註',
      dataIndex: 'note',
    },
    {
      align: 'center',
      title: '操作',
      width: 80,
      render: (record) => {
        return (
          <div className="flex gap-2 items-center">
            <Tooltip title="編輯">
              <Button
                shape="circle"
                size="small"
                icon={<EditOutlined />}
                onClick={() => {
                  modal.confirm({
                    title: '編輯分期收款設定',
                    icon: null,
                    content: (
                      <Form
                        form={editForm}
                        initialValues={record}
                        preserve={false}
                      >
                        <FormItem
                          name="note"
                          label="備註"
                          rules={[{ required: true, message: '請輸入備註' }]}
                        >
                          <Input.TextArea />
                        </FormItem>
                      </Form>
                    ),
                    onOk: async () => {
                      const values = await editForm.validateFields();

                      if (!values) {
                        return Promise.reject();
                      }

                      await updateReceivablePlan({
                        ...values,
                        id: record.id,
                      });
                    },
                  });
                }}
              />
            </Tooltip>
            <Tooltip title="刪除">
              <Button
                shape="circle"
                size="small"
                danger
                icon={<DeleteOutlined />}
                onClick={() => {
                  modal.confirm({
                    title: '刪除分期收款設定',
                    width: 500,
                    content: (
                      <div>
                        <p>確定要刪除此分期收款設定嗎？</p>
                        <p>此操作會一並將相關的應收款項刪除，並且無法復原。</p>
                      </div>
                    ),
                    onOk: async () => {
                      await deleteReceivablePlan(record.id);
                    },
                  });
                }}
              ></Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {contextHolder}
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        size="small"
        loading={isLoading}
        scroll={{
          x: 'max-content',
        }}
        expandable={{
          expandedRowRender: (record) => {
            return <ReceivablePlanReceivablesComponent id={record.id} />;
          },
        }}
        pagination={{
          ...pagination,
          total,
        }}
        title={() => {
          return (
            <div className="flex gap-2 items-center">
              <Button type="primary" onClick={onCreateButtonClick}>
                新增分期收款設定
              </Button>
            </div>
          );
        }}
      />
    </>
  );
}
