import { Button, Spin } from 'antd';
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useRef } from 'react';
import { FormInstance } from 'antd/lib';
import { useProvider } from '../../utils/api-hooks/provider.hook';
import { useIsMutating } from 'react-query';
import { last, split } from 'lodash';

function Link({ to, children }: { to: string; children: React.ReactNode }) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        isActive
          ? 'font-bold border-0 border-solid border-t-0 border-x-0 border-b p-1 px-2'
          : 'p-1 px-2 border-0 hover:border-solid hover:border-t-0 hover:border-x-0 hover:border-b text-black'
      }
    >
      {children}
    </NavLink>
  );
}

export default function ProviderComponent() {
  const navigate = useNavigate();
  const params = useParams();
  const isMutating = useIsMutating(['provider', 'update']);
  const location = useLocation();
  const pathname = last(split(location.pathname, '/'));

  const { id } = params;
  const {
    data: { data } = {},
    isLoading,
    isFetching,
  } = useProvider({ id: id! });
  const { name } = data || {};
  const formRef = useRef<FormInstance>();
  const loading = isLoading || isFetching || !!isMutating;

  return (
    <div className="flex flex-col items-stretch h-full overflow-hidden">
      <div className="flex-shrink-0">
        <span className="text-2xl">{name}</span>
      </div>
      <nav className="flex py-2 gap-1">
        <Link to={`info`}>基本資料</Link>
        <Link to={`business-info`}>商業條件</Link>
        <Link to={`payment-info`}>帳務資料 - 付款</Link>
        <Link to={`receivable-info`}>帳務資料 - 收款</Link>
      </nav>
      <div className="flex-grow pt-4 pb-12 overflow-auto">
        <Spin spinning={loading}>
          <Outlet context={{ formRef, provider: data }} />
        </Spin>
      </div>
      <div className="absolute bottom-0 left-0 flex justify-between w-full p-4 bg-white">
        <Button
          type="link"
          onClick={() => {
            navigate(-1);
          }}
        >
          {'< 返回'}
        </Button>
        {pathname === 'receivable-info' ? null : (
          <Button
            type="primary"
            loading={loading}
            onClick={() => {
              formRef.current?.submit();
            }}
          >
            儲存
          </Button>
        )}
      </div>
    </div>
  );
}
