import { useState } from 'react';
import {
  useRecommendationCount,
  useRecommendationSummary,
} from '../../utils/api.hook';
import { RecommendationStatisticsComponent } from './recommendation-statistics.component';
import dayjs, { Dayjs } from 'dayjs';

export function RecommendationStatisticsContainer() {
  const [timeRange, setTimeRange] = useState<[Dayjs, Dayjs]>([
    dayjs().startOf('week'),
    dayjs().endOf('week'),
  ]);
  const {
    data: { count } = {},
    isLoading: isRecommendationCountLoading,
    isFetching: isRecommendationCountFetching,
  } = useRecommendationCount({
    startTime: timeRange[0].startOf('date').valueOf(),
    endTime: timeRange[1].endOf('date').valueOf(),
  });
  const {
    data: { summary } = {},
    isLoading: isRecommendationSummaryLoading,
    isFetching: isRecommendationSummaryFetching,
  } = useRecommendationSummary({
    startTime: timeRange[0].startOf('date').valueOf(),
    endTime: timeRange[1].endOf('date').valueOf(),
  });

  return (
    <RecommendationStatisticsComponent
      isCountLoading={
        isRecommendationCountLoading || isRecommendationCountFetching
      }
      isSummaryLoading={
        isRecommendationSummaryLoading || isRecommendationSummaryFetching
      }
      count={count}
      summaryList={summary}
      onDateChange={setTimeRange}
      timeRange={timeRange}
    />
  );
}
