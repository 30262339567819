import FormItem from 'antd/es/form/FormItem';
import { UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { FormItemProps } from 'antd/lib';

type FileUploadComponentProps = {
  name?: FormItemProps['name'];
  multiple?: boolean;
  mimeTypes?: string[];
  onChange?: (file: File) => void;
};

export default function ImageUploadComponent(props: FileUploadComponentProps) {
  const { name, multiple, mimeTypes, onChange } = props;
  const [dataUrl, setDataUrl] = useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setDataUrl(e.target?.result as string);
      };

      reader.readAsDataURL(files[0]);
      if (onChange) {
        onChange(files[0]);
      }
    }
  };

  return (
    <FormItem
      name={name || 'file'}
      rules={[
        {
          required: true,
          message: '請上傳檔案',
        },
      ]}
    >
      <label htmlFor={name || 'file'}>
        <div className="border-dashed h-40 w-40 flex items-center justify-center flex-col gap-2 cursor-pointer">
          {dataUrl ? (
            <img src={dataUrl} alt="preview" className="h-full" />
          ) : (
            <>
              <UploadOutlined className="text-4xl" />
              <span>點擊上傳檔案</span>
            </>
          )}
        </div>
        <input
          id={name || 'file'}
          accept={mimeTypes?.join(',')}
          multiple={multiple || false}
          onChange={handleChange}
          className="!hidden"
          type="file"
        />
      </label>
    </FormItem>
  );
}
