import { Checkbox, Form, FormInstance, Input, Radio } from 'antd';
import { useOutletContext } from 'react-router-dom';
import { FindProvider } from '../../utils/api-client';
import FormItem from 'antd/es/form/FormItem';
import { INVOICE_TYPE } from '../../constants/provider-form.types';
import { useEffect } from 'react';
import { useUpdateProvider } from '../../utils/api.hook';

export default function ProviderPaymentInfoComponent() {
  const { formRef, provider } = useOutletContext<{
    formRef: React.RefObject<FormInstance>;
    provider: FindProvider;
  }>();
  const { mutateAsync: updateProvider } = useUpdateProvider();

  useEffect(() => {
    formRef.current?.resetFields();
  }, [formRef, provider]);

  return (
    <Form
      ref={formRef}
      autoComplete="off"
      preserve={false}
      initialValues={provider}
      onFinish={(values) => {
        return updateProvider(values);
      }}
    >
      <FormItem
        name="id"
        label="ID"
        hidden
        rules={[
          {
            required: true,
            message: '請輸入ID',
          },
        ]}
      >
        <Input />
      </FormItem>
      <FormItem name={['paymentInfo', 'receiptType']} label="收據類型">
        <Radio.Group
          options={[
            { label: '發票', value: INVOICE_TYPE.INVOICE },
            { label: '收據', value: INVOICE_TYPE.RECEIPT },
          ]}
        />
      </FormItem>
      <FormItem name={['paymentInfo', 'account']} label="匯款帳號">
        <Input />
      </FormItem>
      <FormItem name={['paymentInfo', 'accountName']} label="匯款戶名">
        <Input />
      </FormItem>
      <FormItem name={['paymentInfo', 'bankName']} label="匯款銀行名稱">
        <Input />
      </FormItem>
      <FormItem name={['paymentInfo', 'bankBranchName']} label="匯款分行名稱">
        <Input />
      </FormItem>
      <FormItem
        name={['paymentInfo', 'shouldInvoice']}
        label="需回開發票"
        valuePropName="checked"
      >
        <Checkbox />
      </FormItem>
    </Form>
  );
}
