import { ColorPicker, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import { FormInstance } from 'antd/lib';
import { useImperativeHandle } from 'react';
import { ProviderType } from '../../utils/api-client';
import { ColorFactory } from 'antd/es/color-picker/color';

type ProviderTypeFormProps = {
  formRef: React.MutableRefObject<FormInstance | undefined>;
  data?: ProviderType;
};

export function ProviderTypeFormComponent(props: ProviderTypeFormProps) {
  const [form] = useForm();

  useImperativeHandle(props.formRef, () => form, [form]);

  const { data } = props;

  return (
    <Form
      autoComplete="off"
      form={form}
      preserve={false}
      initialValues={
        data
          ? {
              name: data.name,
              color: new ColorFactory(data.color),
            }
          : {
              color: null,
            }
      }
    >
      <FormItem
        label="類型"
        name="name"
        rules={[{ required: true, message: '請輸入類型' }]}
      >
        <Input />
      </FormItem>
      <FormItem
        label="顏色"
        name="color"
        rules={[{ required: true, message: '請選擇顏色' }]}
      >
        <ColorPicker format="hex" defaultFormat="hex" />
      </FormItem>
    </Form>
  );
}
