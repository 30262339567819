import { Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useProviderTypes } from '../utils/api-hooks/provider.hook';
import { map } from 'lodash';

export function ProviderTypeSelectorComponent() {
  const {
    data: { data: providerTypes } = {},
    isLoading: isProviderTypesLoading,
  } = useProviderTypes();
  return (
    <FormItem
      name="type"
      label="供應商類型"
      rules={[
        {
          required: true,
          message: '請選擇類型',
        },
      ]}
    >
      <Select
        loading={isProviderTypesLoading}
        options={map(providerTypes, (t) => ({
          label: (
            <div className="flex items-center gap-2">
              <span
                style={{ backgroundColor: t.color }}
                className={`w-4 aspect-square`}
              ></span>
              <span>{t.name}</span>
            </div>
          ),
          value: t.name,
        }))}
      />
    </FormItem>
  );
}
