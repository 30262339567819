import { useQuery } from 'react-query';
import { ReceivablePlansReceivablesApi } from '../api-client';

export function useReceivablePlanReceivables(data: {
  receivablePlanId: string;
}) {
  return useQuery(['receivablePlanReceivables', data], () => {
    return new ReceivablePlansReceivablesApi()
      .receivablePlanReceivableControllerGetReceivables(data.receivablePlanId)
      .then((response) => {
        return response.data;
      });
  });
}
