import { useEffect } from 'react';
import { Form, DatePicker } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import locale from 'antd/es/date-picker/locale/zh_TW';
import type { Dayjs } from 'dayjs';

type ExportMenusFormProps = {
  form: FormInstance;
  data?: {
    time: [Dayjs, Dayjs];
  };
};

export const ExportMenusForm = (props: ExportMenusFormProps) => {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form} autoComplete="off" initialValues={props.data}>
      <Form.Item
        label="時間範圍"
        name="time"
        rules={[{ required: true, message: '請選擇時間範圍' }]}
      >
        <DatePicker.RangePicker locale={locale} />
      </Form.Item>
    </Form>
  );
};
