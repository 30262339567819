import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ProvidersApi, ProviderTypesApi } from '../api-client';

export function useProvider(data: { id: string }) {
  return useQuery(['provider', data], async () => {
    return new ProvidersApi()
      .providerControllerReadProvider(data.id)
      .then((res) => res.data);
  });
}

export function useProviderTypes() {
  return useQuery('providerTypes', () => {
    return new ProviderTypesApi()
      .providerTypeControllerFindTypes()
      .then((res) => res.data);
  });
}

export function useCreateProviderType() {
  const queryClient = useQueryClient();

  return useMutation(
    (data: { name: string; color: string }) => {
      return new ProviderTypesApi().providerTypeControllerCreate(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('providerTypes');
      },
    }
  );
}

export function useDeleteProviderType() {
  const queryClient = useQueryClient();

  return useMutation(
    (id: string) => {
      return new ProviderTypesApi().providerTypeControllerDeleteType(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('providerTypes');
      },
    }
  );
}

export function useUpdateProviderType() {
  const queryClient = useQueryClient();

  return useMutation(
    (data: { id: string; name: string; color: string }) => {
      const { id, ...rest } = data;
      return new ProviderTypesApi().providerTypeControllerUpdateType(id, rest);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('providerTypes');
      },
    }
  );
}
