import { Button, Form, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import TextArea from 'antd/es/input/TextArea';
import { useEffect } from 'react';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import { ProviderSelectorContainer } from '../shared/provider-selector/provider-selector.containers';
import FormList from 'antd/es/form/FormList';
import { map, size } from 'lodash';
import ErrorList from 'antd/es/form/ErrorList';
import ImageUploadComponent from '../shared/image-upload/image-upload.component';

export function PublishMessageForm({
  form,
  needProvider = false,
}: {
  form: FormInstance;
  needProvider?: boolean;
}) {
  useEffect(() => {
    form.resetFields();
  }, [form]);

  return (
    <Form
      form={form}
      autoComplete="off"
      initialValues={{
        messages: [
          {
            type: 'TEXT',
          },
        ],
      }}
    >
      {needProvider && <ProviderSelectorContainer multiple isRequired />}
      <FormList
        name="messages"
        rules={[
          {
            validator: async (_, messages) => {
              if (size(messages) === 0) {
                return Promise.reject('請至少新增一則訊息');
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <div className="flex flex-col gap-4">
            {map(fields, (field, index) => (
              <div
                className="p-4 border border-dashed space-y-2"
                key={field.key}
              >
                <div className="flex items-center justify-between">
                  <div className="text-xl font-bold">訊息 {index + 1}</div>
                  <Button
                    onClick={() => remove(field.name)}
                    ghost
                    danger
                    icon={<DeleteOutlined />}
                  />
                </div>
                <FormItem
                  name={[field.name, 'type']}
                  label="訊息類型"
                  rules={[
                    {
                      required: true,
                      message: '請選擇訊息類型',
                    },
                  ]}
                >
                  <Select
                    options={[
                      { label: '文字', value: 'TEXT' },
                      { label: '圖片', value: 'IMAGE' },
                    ]}
                  />
                </FormItem>
                <FormItem
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => {
                    return (
                      prevValues.messages[index]?.type !==
                        currentValues.messages[index]?.type ||
                      prevValues.messages[index]?.action !==
                        currentValues.messages[index]?.action
                    );
                  }}
                >
                  {({ getFieldValue, setFieldValue }) => {
                    const type = getFieldValue([
                      'messages',
                      field.name,
                      'type',
                    ]);

                    const onFileChange = (file: File) => {
                      setFieldValue(['messages', field.name, 'image'], file);
                    };

                    return type === 'IMAGE' ? (
                      <div className="flex items-center justify-center">
                        <ImageUploadComponent
                          mimeTypes={['image/jpeg', 'image/png']}
                          multiple={false}
                          name={[field.name, 'imageName']}
                          onChange={onFileChange}
                        />
                      </div>
                    ) : (
                      <FormItem
                        name={[field.name, 'text']}
                        rules={[
                          {
                            required: true,
                            message: '請輸入訊息內容',
                          },
                        ]}
                      >
                        <TextArea rows={5} />
                      </FormItem>
                    );
                  }}
                </FormItem>
              </div>
            ))}
            <Button
              block
              disabled={size(fields) >= 5}
              icon={<PlusOutlined />}
              type="dashed"
              onClick={() => {
                add({
                  type: 'TEXT',
                });
              }}
            >
              新增訊息
            </Button>
            <ErrorList errors={errors} className="text-red-400" />
          </div>
        )}
      </FormList>
    </Form>
  );
}
