export enum RECEIVABLE_STATE {
  RECEIVED = 'RECEIVED',
  UNRECEIVED = 'UNRECEIVED',
}

export const RECEIVABLE_STATE_LABEL: Record<RECEIVABLE_STATE, string> = {
  [RECEIVABLE_STATE.RECEIVED]: '已收款',
  [RECEIVABLE_STATE.UNRECEIVED]: '未收款',
};

export const RECEIVABLE_STATE_COLOR: Record<RECEIVABLE_STATE, string> = {
  [RECEIVABLE_STATE.RECEIVED]: 'green',
  [RECEIVABLE_STATE.UNRECEIVED]: 'red',
};
