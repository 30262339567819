import { useEffect, useMemo, useState } from 'react';
import { groupBy, isEmpty, keys, map, reduce, sortBy } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
  useLocationTags,
  useMealsListProviders,
  useScheduleMeals,
  useUpdateScheduleMeal,
  useDeleteScheduleMeal,
  useUpdateScheduleMealStatus,
  useUpdateScheduleMealsRank,
  useScheduleMealsNote,
  useUpateScheduleMealsNote,
  useExportDeliveryMeals,
  useUpdateLocationTag,
  useScheduleSuggestMeals,
} from '../../utils/api.hook';

import { ScheduleMenusComponent } from './schedule-menus.component';
import schedules from '../schedules';

const distanceDay = 14;

export function ScheduleMenusContainer() {
  const [selectDate, setSelectDate] = useState(dayjs().day(1));
  const [listData, setListData] = useState<
    Record<
      string,
      {
        id: string;
        name: string;
        markColor: string;
        rank: number;
        data: schedules.MappingGroupByTimestamp;
      }
    >
  >({});

  const { data: { providers } = {} } = useMealsListProviders();
  const { data: { deliveryMeals: todayDeliveryMeals = [] } = {} } =
    useScheduleMeals({
      startTime: dayjs().startOf('day').valueOf(),
      endTime: dayjs().endOf('day').valueOf(),
    });
  const { data: { deliveryMeals: lastMonthDeliveryMeals = [] } = {} } =
    useScheduleMeals({
      startTime: selectDate.subtract(1, 'month').startOf('day').valueOf(),
      endTime: selectDate
        .subtract(1, 'day')
        .add(distanceDay - 1, 'days')
        .endOf('day')
        .valueOf(),
    });
  const {
    data: { suggestions: suggestDeliveryMeals = [] } = {},
    isLoading: isSuggestionDeliveryMealsLoading,
    isFetching: isSuggestionDeliveryMealsFetching,
    refetch: refetchSuggestDeliveryMeals,
  } = useScheduleSuggestMeals({
    startTime: dayjs().startOf('day').valueOf(),
    // suggsetionsCount: 1,
    // groupSize: 6,
  });
  const { mutateAsync: updateScheduleMeals } = useUpdateScheduleMeal();
  const { mutateAsync: deleteScheduleMeals } = useDeleteScheduleMeal();
  const { mutateAsync: updateScheduleMealStatus } =
    useUpdateScheduleMealStatus();
  const { mutateAsync: updateScheduleMealsRank } = useUpdateScheduleMealsRank();
  const { data: noteData = [] } = useScheduleMealsNote({
    startTime: selectDate.startOf('day').add(0, 'days').valueOf(),
    endTime: selectDate
      .endOf('day')
      .add(distanceDay - 1, 'days')
      .valueOf(),
  });
  const { mutateAsync: upateScheduleMealsNote } = useUpateScheduleMealsNote();
  const { data: { locationTags } = {} } = useLocationTags();
  const { mutateAsync: updateLocationTag } = useUpdateLocationTag();
  const { mutateAsync: exportDeliveryMeals } = useExportDeliveryMeals();

  const providerTagsInformation = useMemo(() => {
    if (isEmpty(providers)) {
      return {
        options: [],
        mappingObject: {},
      };
    }

    const sortedOptions = sortBy(providers, [
      'type',
      (provider) => Number(provider.idNumber),
    ]);
    const optionsGroupByType = groupBy(sortedOptions, 'type');

    return {
      options: keys(optionsGroupByType).map((typeKey) => ({
        label: typeKey,
        options: optionsGroupByType[typeKey].map(
          (item: { id: string; name: string; lastPickTime: number }) => {
            let lastPickInfo = item.lastPickTime
              ? `（上次：${dayjs(item.lastPickTime).format('YYYY-MM-DD')}）`
              : '';
            return {
              label: `${item.name} ${lastPickInfo}`,
              value: item.id,
              lastPickTime: item.lastPickTime,
            };
          }
        ),
      })),
      mappingObject: providers.reduce(
        (accumulate: { [id: string]: {} }, item: { id: string }) => {
          accumulate[item.id] = item;
          return accumulate;
        },
        {}
      ),
    };
  }, [providers]);

  const noteMapping = useMemo(() => {
    return reduce(
      noteData,
      (
        accumulate: { [time: number]: { [locationTagId: string]: string } },
        item: {
          locationTagId: string;
          time: number;
          note: string;
        }
      ) => {
        if (isEmpty(accumulate[item.time])) {
          accumulate[item.time] = {};
        }
        accumulate[item.time][item.locationTagId] = item.note;

        return accumulate;
      },
      {}
    );
  }, [noteData]);

  const deliveredMeals = useMemo(() => {
    const mealsByLocationTag = groupBy(
      lastMonthDeliveryMeals,
      'locationTag.id'
    );
    return reduce(
      keys(mealsByLocationTag),
      (accumulate: any, locationTagId: string) => {
        if (!(locationTagId in accumulate)) {
          accumulate[locationTagId] = groupBy(
            mealsByLocationTag[locationTagId],
            'provider.id'
          );
        }
        return accumulate;
      },
      {}
    );
  }, [lastMonthDeliveryMeals]);

  useEffect(() => {
    const initListData = async () => {
      let timestampAccumulate: { [timestamp: number]: schedules.CellData[] } =
        {};
      let timestampKey: Dayjs;

      for (let index = 0; index < distanceDay; index++) {
        timestampKey = selectDate.startOf('day').add(index, 'days');
        if (timestampKey.get('day') > 0 && timestampKey.get('day') < 6) {
          timestampAccumulate[timestampKey.valueOf()] = [];
        }
      }

      const mappingGroupByTags = groupBy(
        lastMonthDeliveryMeals,
        'locationTag.id'
      );
      let mappingGroupByTimestamp: schedules.MappingGroupByTimestamp = {};

      const initData = reduce(
        locationTags,
        (
          accumulate: {
            [id: string]: schedules.LocationTag & {
              markColor: string;
              rank: number;
              data: schedules.MappingGroupByTimestamp;
            };
          },
          item: schedules.LocationTag & {
            markColor: string;
            rank: number;
          }
        ) => {
          let data: schedules.MappingGroupByTimestamp = {};
          if (!isEmpty(mappingGroupByTags[item.id])) {
            mappingGroupByTimestamp = groupBy(
              mappingGroupByTags[item.id],
              'time'
            );

            for (let timestamp of keys(timestampAccumulate)) {
              data[timestamp] = !isEmpty(mappingGroupByTimestamp[timestamp])
                ? map(mappingGroupByTimestamp[timestamp], (item) => ({
                    time: timestamp,
                    id: item.id,
                    provider: item.provider,
                    locationTag: item.locationTag,
                    rank: item.rank,
                    status: item.status,
                    needToRecycleBags: item.needToRecycleBags,
                  }))
                : [];
            }
          } else {
            data = timestampAccumulate;
          }

          accumulate[item.id] = {
            ...item,
            data,
          };

          return accumulate;
        },
        {}
      );

      setListData(initData);
    };

    if (selectDate.isValid() && !isEmpty(locationTags)) {
      initListData();
    }
  }, [lastMonthDeliveryMeals, selectDate, locationTags]);

  return (
    <DndProvider backend={HTML5Backend}>
      <ScheduleMenusComponent
        locationTags={locationTags}
        providerTagsInformation={providerTagsInformation}
        updateScheduleMeals={updateScheduleMeals}
        deleteScheduleMeals={deleteScheduleMeals}
        updateScheduleMealStatus={updateScheduleMealStatus}
        updateScheduleMealsRank={updateScheduleMealsRank}
        upateScheduleMealsNote={upateScheduleMealsNote}
        exportDeliveryMeals={exportDeliveryMeals}
        updateLocationTag={updateLocationTag}
        listData={listData}
        noteData={noteMapping}
        selectDate={selectDate}
        setSelectDate={setSelectDate}
        todayDeliveryMeals={todayDeliveryMeals}
        deliveredMeals={deliveredMeals}
        suggestDeliveryMeals={suggestDeliveryMeals}
        isSuggestionDeliveryMealsLoading={
          isSuggestionDeliveryMealsLoading || isSuggestionDeliveryMealsFetching
        }
        refetchSuggestDeliveryMeals={refetchSuggestDeliveryMeals}
      />
    </DndProvider>
  );
}
