export enum INVOICE_TYPE {
  INVOICE = 'INVOICE',
  RECEIPT = 'RECEIPT',
}

export enum COMMERCIAL_TERMS {
  BUYOUT = 'BUYOUT',
  COMMISSION = 'COMMISSION',
  GAMBLING = 'GAMBLING',
}

export enum GAMBLING_DISCOUNT_TYPE {
  PERCENTAGE = 'PERCENTAGE',
  FIXED = 'FIXED',
}

export const GAMBLING_DISCOUNT_TYPE_LABEL = {
  [GAMBLING_DISCOUNT_TYPE.PERCENTAGE]: '百分比',
  [GAMBLING_DISCOUNT_TYPE.FIXED]: '固定金額',
};

export const GAMBLING_DISCOUNT_TYPE_OPTIONS = [
  {
    label: GAMBLING_DISCOUNT_TYPE_LABEL[GAMBLING_DISCOUNT_TYPE.PERCENTAGE],
    value: GAMBLING_DISCOUNT_TYPE.PERCENTAGE,
  },
  {
    label: GAMBLING_DISCOUNT_TYPE_LABEL[GAMBLING_DISCOUNT_TYPE.FIXED],
    value: GAMBLING_DISCOUNT_TYPE.FIXED,
  },
];

export enum PROVIDER_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export const PROVIDER_STATUS_LABEL = {
  [PROVIDER_STATUS.ACTIVE]: '合作中',
  [PROVIDER_STATUS.INACTIVE]: '結束合作',
};

export const PROVIDER_STATUS_OPTIONS = [
  {
    label: PROVIDER_STATUS_LABEL[PROVIDER_STATUS.ACTIVE],
    value: PROVIDER_STATUS.ACTIVE,
  },
  {
    label: PROVIDER_STATUS_LABEL[PROVIDER_STATUS.INACTIVE],
    value: PROVIDER_STATUS.INACTIVE,
  },
];
