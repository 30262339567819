import InstallmentsTableComponent from '../../shared/installments-table/installments-table.component';

export default function InstallmentsContainer() {
  return (
    <div className="flex flex-col gap-2">
      <span className="text-2xl font-bold">分期收款管理</span>
      <InstallmentsTableComponent />
    </div>
  );
}
