import { useMemo } from 'react';
import { Pie, PieConfig } from '@ant-design/charts';
import { orderBy, round, size, slice, sumBy } from 'lodash';

type PieComponentProps = {
  data: Array<{
    type: string;
    value: number;
  }>;
};

const topCount = 19;

export default function PieComponent(props: PieComponentProps) {
  const { data } = props;
  const sorted = useMemo(
    () => orderBy(data || [], ['value'], ['desc']),
    [data]
  );
  const topTen = useMemo(() => slice(sorted, 0, topCount), [sorted]);
  const others = useMemo(
    () => ({
      type: '其他',
      value: sumBy(slice(sorted, topCount), 'value'),
    }),
    [sorted]
  );

  const config: PieConfig = {
    radius: 1,
    data: size(data) <= topCount ? topTen : [...topTen, others],
    angleField: 'value',
    colorField: 'type',
    label: {
      offset: 40,
      formatter: (
        _a: any,
        item: { type: string; value: number },
        _index: number,
        data: Array<{ type: string; value: number }>
      ) => {
        return `${item.type}: ${item.value}(${round((item.value / sumBy(data, 'value')) * 100)}%)`;
      },
    },
    tooltip: (
      item: { type: string; value: number },
      _index: number,
      data: Array<{ type: string; value: number }>
    ) => {
      return {
        name: item.type,
        value: `${item.value} (${((item.value / sumBy(data, 'value')) * 100).toFixed(2)}%)`,
      };
    },
    legend: {
      color: {
        position: 'right',
        // 顯示 ${type} - ${value}(${percent}%) 的格式
        labelFormatter: (val: string) => {
          const item = data.find((d) => d.type === val);

          if (!item) {
            return val;
          }

          return `${val} - ${item.value}(${round(((item.value || 0) / sumBy(data, 'value')) * 100)}%)`;
        },
      },
    },
  };
  return <Pie {...config} />;
}
