import { useRecommendationRanking } from '../utils/api.hook';
import { RecommendationsComponent } from './recommendations.component';

export function RecommendationsContainer() {
  const { data: { ranking } = {}, isLoading: isRecommendationRankingLoading } =
    useRecommendationRanking({ top: 30 });

  return (
    <RecommendationsComponent
      ranking={ranking}
      isLoading={isRecommendationRankingLoading}
    />
  );
}
