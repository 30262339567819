import { useEffect } from 'react';
import { Form, Space, Button, ColorPicker } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { map, size } from 'lodash';

import FormList from 'antd/es/form/FormList';

type RowStyleFormProps = {
  form: FormInstance;
  data: { listData: {}[] };
};

export const RowStyleForm = (props: RowStyleFormProps) => {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form} autoComplete="off" initialValues={props.data}>
      <FormList name="listData">
        {(fields, { add, remove, move }, { errors }) => (
          <div className="flex flex-col gap-1">
            {map(fields, (field, index) => (
              <div
                className="flex flex-row items-center justify-between"
                key={field.key}
              >
                <div className="text-400">
                  {props.form.getFieldValue(['listData', field.name, 'name'])}
                </div>

                <Space align="start">
                  <FormItem
                    label="顏色"
                    name={[field.name, 'markColor']}
                    rules={[{ required: true, message: '請選擇顏色' }]}
                    style={{ marginBottom: 0 }}
                  >
                    <ColorPicker format="hex" defaultFormat="hex" />
                  </FormItem>

                  <Button
                    icon={<ArrowUpOutlined />}
                    disabled={index === 0}
                    onClick={() => {
                      move(field.name, field.name - 1);
                    }}
                  />

                  <Button
                    icon={<ArrowDownOutlined />}
                    disabled={index === size(fields) - 1}
                    onClick={() => {
                      move(field.name, field.name + 1);
                    }}
                  />
                </Space>
              </div>
            ))}
          </div>
        )}
      </FormList>
    </Form>
  );
};
