import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Row, Timeline, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import mapboxgl from 'mapbox-gl';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { isEmpty, map } from 'lodash';
import { formatTimeStr } from 'antd/es/statistic/utils';

import 'mapbox-gl/dist/mapbox-gl.css';

import { getMapDirection, useMapGeocoding } from '../../utils/api.map';

import schedules from '../schedules';

type MealsMapComponentProps = {
  providerIds: [string];
  setIsMapReady: Function;
  providerTagsInformation: {
    options: { label: string; options: { label: string; value: string }[] }[];
    mappingObject: {
      [key: string]: {
        name: string;
        type: string;
        address: string;
      };
    };
  };
};

type BasicRouteInfoProps = {
  duration?: number;
  distance?: number;
};

type Step = {
  geometry: GeoJSON.LineString;
} & BasicRouteInfoProps;

type Legs = {
  steps: Step[];
} & BasicRouteInfoProps;

const language = new MapboxLanguage({ defaultLanguage: 'zh-Hant' });

const calcBoundsFromCoordinates = (
  coordinates: Array<schedules.Position>
): [schedules.Position, schedules.Position] => {
  return [
    [Math.min(...map(coordinates, 0)), Math.min(...map(coordinates, 1))],
    [Math.max(...map(coordinates, 0)), Math.max(...map(coordinates, 1))],
  ];
};

export function MealsMapComponent({
  setIsMapReady,
  ...props
}: MealsMapComponentProps) {
  const mapRef = useRef<mapboxgl.Map>();
  const markerRef = useRef<mapboxgl.Popup[]>();

  const { mutateAsync: getMapGeocoding } = useMapGeocoding();
  const [routeData, setRouteData] = useState<
    {
      legs: Legs[];
    } & BasicRouteInfoProps
  >({ legs: [] });

  const timelineItems = useMemo(() => {
    return map(props.providerIds, (providerId, index) => ({
      children: (
        <>
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
          >{`${props.providerTagsInformation.mappingObject[providerId].name} (${props.providerTagsInformation.mappingObject[providerId].address})`}</Typography.Paragraph>

          <BasicRouteInfo {...routeData.legs[index]} />
        </>
      ),
    }));
  }, [
    routeData,
    props.providerIds,
    props.providerTagsInformation.mappingObject,
  ]);

  const getAddressGeos = useCallback(
    async (providerIds: string[] | undefined) => {
      let geos: Array<schedules.Position> = [];
      for (const providerId of providerIds || []) {
        const address =
          props.providerTagsInformation.mappingObject[providerId]?.address;
        const cacheMap: { [address: string]: schedules.Position } = JSON.parse(
          window.localStorage.getItem('SIMPLY_CACHE_GEOS') || '{}'
        );

        if (!isEmpty(address) && isEmpty(cacheMap[address])) {
          try {
            let res = (await getMapGeocoding({ keyword: address }))?.features[0]
              ?.geometry?.coordinates;
            if (!isEmpty(res)) {
              geos.push(res);
              window.localStorage.setItem(
                'SIMPLY_CACHE_GEOS',
                JSON.stringify({
                  ...cacheMap,
                  [address]: res,
                })
              );
            }
          } catch (error) {}
        } else if (!isEmpty(address)) {
          geos.push(cacheMap[address]);
        }
      }
      return geos;
    },
    [getMapGeocoding, props.providerTagsInformation]
  );

  const updateMapRoute = (geometry: GeoJSON.LineString) => {
    if (!isEmpty(mapRef.current)) {
      const mapInstance = mapRef.current;
      const mySource: mapboxgl.GeoJSONSource = mapInstance.getSource(
        'route'
      ) as mapboxgl.GeoJSONSource;
      mySource.setData({
        type: 'Feature',
        properties: {},
        geometry,
      });
    }
  };

  const updateMapMarker = (geos: Array<schedules.Position>) => {
    if (mapRef.current && geos.length) {
      let markers = Array<mapboxgl.Popup>(),
        popup;
      for (let index = 0; index < geos.length; index++) {
        popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          offset: 0,
        })
          .setHTML(`<h3 class="marker-popup">${index + 1}</h3>`)
          .setLngLat(geos[index])
          .addTo(mapRef.current);

        markers.push(popup);
      }

      markerRef.current = markers;
    }
  };

  useEffect(() => {
    if (
      !isEmpty(props.providerIds) &&
      !isEmpty(props.providerTagsInformation?.mappingObject)
    ) {
      const updateMaps = async () => {
        const geos = await getAddressGeos(props.providerIds);
        let firstRoute: {
          legs: Legs[];
          geometry: GeoJSON.LineString;
        } = {
          legs: [],
          geometry: {
            coordinates: Array<schedules.Position>(),
            type: 'LineString',
          },
        };

        if (!isEmpty(geos)) {
          updateMapMarker(geos);

          // auto zoom-in maps
          const [swCoordinates, neCoordinates] =
            calcBoundsFromCoordinates(geos);
          if (mapRef.current) {
            mapRef.current.fitBounds([swCoordinates, neCoordinates], {
              padding: 40,
            });
          }

          // update the route of point to point in the maps
          if (geos.length > 1) {
            firstRoute = (
              await getMapDirection(map(geos, (geo) => geo.join('%2C')))
            )?.routes[0];
          }

          setRouteData(firstRoute);
          updateMapRoute(firstRoute.geometry);
        }
      };

      updateMaps();
    }

    return clearMapMarker;
  }, [props.providerIds, props.providerTagsInformation, getAddressGeos]);

  const clearMapMarker = () => {
    if (!isEmpty(mapRef.current)) {
      const mapInstance = mapRef.current;
      const mySource: mapboxgl.GeoJSONSource = mapInstance.getSource(
        'route'
      ) as mapboxgl.GeoJSONSource;
      mySource.setData({
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: [],
        },
      });
    }

    if (markerRef.current) {
      for (let index = 0; index < markerRef.current.length; index++) {
        markerRef.current[index].remove();
      }
    }
  };

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_PUBLIC_KEY || '';
    let mapInstance = new mapboxgl.Map({
      container: 'map-container',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [121.5410607, 25.0387266],
      zoom: 12,
    });
    mapInstance.addControl(language);

    mapInstance.on('load', () => {
      mapInstance.addSource('route', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            coordinates: [121.5410607, 25.0387266],
            type: 'Point',
          },
        },
      });

      mapInstance.addLayer({
        id: 'layer',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#324dff',
          'line-width': 8,
        },
      });

      mapRef.current = mapInstance;
      setIsMapReady(true);
    });

    return () => {
      mapInstance.remove();
      setIsMapReady(false);
    };
  }, [setIsMapReady]);

  return (
    <>
      <Button
        icon={<SearchOutlined />}
        className="button-to-maps"
        size="small"
        type="link"
        href={`https://www.google.com.tw/maps/dir/${map(props.providerIds, (providerId) => props.providerTagsInformation.mappingObject[providerId].address).join('/')}`}
        target="_blank"
        disabled={!props.providerIds?.length}
        style={{ marginBottom: 10 }}
      >
        在Google Maps上查看更多細節
      </Button>

      <Row gutter={10} wrap={false}>
        <Col span={12}>
          <div
            id="map-container"
            style={{ width: '100%', height: '200px', overflow: 'hidden' }}
          ></div>
        </Col>

        <Col span={12}>
          {timelineItems.length > 2 && (
            <div className="mb-1">
              <BasicRouteInfo {...routeData} />
            </div>
          )}

          <Timeline items={timelineItems} />
        </Col>
      </Row>
    </>
  );
}

const BasicRouteInfo = ({ duration, distance }: BasicRouteInfoProps) => {
  if (distance && duration) {
    return (
      <Typography.Paragraph type="secondary" style={{ marginBottom: 0 }}>
        {formatTimeStr(duration * 1000, 'mm分ss秒')} （
        {Math.round((distance / 1000 + Number.EPSILON) * Math.pow(10, 2)) /
          Math.pow(10, 2)}
        公里）
      </Typography.Paragraph>
    );
  }

  return null;
};
