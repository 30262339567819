import { Form, Input, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useLineBotJoinLogs, useUpdateProvider } from '../../utils/api.hook';
import { useEffect, useMemo } from 'react';
import { map } from 'lodash';
import moment from 'moment';
import { useOutletContext } from 'react-router-dom';
import { FormInstance } from 'antd/lib';
import { FindProvider } from '../../utils/api-client';
import { ProviderTypeSelectorComponent } from '../../shared/provider-type-selector.component';
import { PROVIDER_STATUS_OPTIONS } from '../../constants/provider-form.types';

export default function ProviderInfoComponent() {
  const { formRef, provider } = useOutletContext<{
    formRef: React.RefObject<FormInstance>;
    provider: FindProvider;
  }>();
  const { data: { logs } = {} } = useLineBotJoinLogs();
  const { mutateAsync: updateProvider } = useUpdateProvider();

  useEffect(() => {
    formRef.current?.resetFields();
  }, [formRef, provider]);

  const logOptions = useMemo(
    () =>
      map(logs, (log) => ({
        label: `${moment(log.time).utcOffset(8).format('YYYY-MM-DD HH:mm')} - ${
          log.id
        }`,
        value: log.id,
      })),
    [logs]
  );

  return (
    <Form
      ref={formRef}
      autoComplete="off"
      preserve={false}
      initialValues={provider}
      onFinish={(values) => {
        updateProvider(values);
      }}
    >
      <FormItem
        label="合作狀態"
        name="status"
        rules={[
          {
            required: true,
            message: '請選擇合作狀態',
          },
        ]}
      >
        <Select options={PROVIDER_STATUS_OPTIONS} />
      </FormItem>
      <FormItem
        name="id"
        label="ID"
        hidden
        rules={[
          {
            required: true,
            message: '請輸入ID',
          },
        ]}
      >
        <Input />
      </FormItem>
      <FormItem
        name="idNumber"
        label="編號"
        rules={[
          {
            required: true,
            message: '請輸入編號',
          },
        ]}
      >
        <Input />
      </FormItem>
      <FormItem
        name="name"
        label="名稱"
        rules={[
          {
            required: true,
            message: '請輸入名稱',
          },
        ]}
      >
        <Input />
      </FormItem>
      <FormItem
        name="nickname"
        label="簡稱"
        rules={[
          {
            required: true,
            message: '請輸入簡稱',
          },
        ]}
      >
        <Input />
      </FormItem>
      <ProviderTypeSelectorComponent />
      <FormItem
        name="lineGroupId"
        label="LINE Group ID"
        rules={[
          {
            required: true,
            message: '請輸入LINE Group ID',
          },
        ]}
      >
        <Select options={logOptions} />
      </FormItem>
      <FormItem label="食品業者登錄字號" name="foodBusinessRegistrationNumber">
        <Input />
      </FormItem>
      <FormItem
        label="統一編號"
        name="taxId"
        rules={[
          {
            type: 'string',
            len: 8,
            message: '統一編號長度為 8',
          },
        ]}
      >
        <Input />
      </FormItem>
      <FormItem label="地址" name="address">
        <Input />
      </FormItem>
      <FormItem label="地址備註" name="addressComment">
        <Input />
      </FormItem>
    </Form>
  );
}
