import {
  memo,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {
  Form,
  Space,
  Badge,
  Select,
  Button,
  Divider,
  Switch,
  Flex,
  Skeleton,
  List,
  Typography,
  DatePicker,
  ColorPicker,
  message,
} from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import locale from 'antd/es/date-picker/locale/zh_TW';
import { PresetStatusColorType } from 'antd/es/_util/colors';
import useModal from 'antd/es/modal/useModal';
import FormItem from 'antd/es/form/FormItem';
import {
  DeleteOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import {
  cloneDeep,
  difference,
  filter,
  findIndex,
  forEach,
  isEmpty,
  keyBy,
  map,
  mapValues,
  size,
  sortBy,
  uniqBy,
  values,
} from 'lodash';

import 'mapbox-gl/dist/mapbox-gl.css';

import {
  PROVIDER_STATUS_MAPPING,
  PROVIDER_STATUS_TYPE,
} from '../../constants/schedule-meals.types';
import schedules from '../schedules';

import { CalendarHeader, CalendarCell } from './meals-calendar.component';
import { MealsMapComponent } from './meals-map.component';
import { useProviderTypes } from '../../utils/api-hooks/provider.hook';
import FormList from 'antd/es/form/FormList';
import { ColorFactory } from 'antd/es/color-picker/color';

const todayTimestamp = dayjs().startOf('day');

const PROVIDER_STATUS_OPTIONS = [
  {
    label: (
      <Badge
        status={
          PROVIDER_STATUS_MAPPING[
            PROVIDER_STATUS_TYPE.PENDING
          ] as PresetStatusColorType
        }
      />
    ),
    value: PROVIDER_STATUS_TYPE.PENDING,
  },
  {
    label: (
      <Badge
        status={
          PROVIDER_STATUS_MAPPING[
            PROVIDER_STATUS_TYPE.CONFIRMED
          ] as PresetStatusColorType
        }
      />
    ),
    value: PROVIDER_STATUS_TYPE.CONFIRMED,
  },
  {
    label: (
      <Badge
        status={
          PROVIDER_STATUS_MAPPING[
            PROVIDER_STATUS_TYPE.REJECTED
          ] as PresetStatusColorType
        }
      />
    ),
    value: PROVIDER_STATUS_TYPE.REJECTED,
  },
];

type DeliveryMeals = Array<{
  id: string;
  time: number;
  provider: schedules.Provider;
  locationTag: schedules.LocationTag;
  driver: schedules.Driver;
  rank: number;
  needToRecycleBags: boolean;
  status:
    | PROVIDER_STATUS_TYPE.PENDING
    | PROVIDER_STATUS_TYPE.CONFIRMED
    | PROVIDER_STATUS_TYPE.REJECTED;
}>;

type ScheduleMealsComponentProps = {
  locationTags: Array<schedules.LocationTag>;
  providerTagsInformation: {
    options: { label: string; options: { label: string; value: string }[] }[];
    mappingObject: {
      [key: string]: {
        name: string;
        type: string;
        address: string;
      };
    };
  };
  updateScheduleMeals: Function;
  deleteScheduleMeals: Function;
  updateScheduleMealStatus: Function;
  updateScheduleMealsRank: Function;
  upateScheduleMealsNote: Function;
  exportDeliveryMeals: Function;
  updateLocationTag: (values: {
    id: string;
    name: string;
    description: string;
    textColor: string;
    backgroundColor: string;
    pay: number;
  }) => Promise<any>;
  selectDate: Dayjs;
  setSelectDate: Function;
  listData: Record<
    string,
    {
      id: string;
      name: string;
      rank: number;
      markColor: string;
      data: schedules.MappingGroupByTimestamp;
    }
  >;
  noteData: { [time: number]: { [locationTagId: string]: string } };
  todayDeliveryMeals: DeliveryMeals;
  deliveredMeals: any;
};

const ExportDeliveryMealsForm = (props: {
  form: FormInstance;
  data?: {
    time: [Dayjs, Dayjs];
  };
}) => {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form} autoComplete="off" initialValues={props.data}>
      <Form.Item
        label="時間範圍"
        name="time"
        rules={[{ required: true, message: '請選擇時間範圍' }]}
      >
        <DatePicker.RangePicker locale={locale} />
      </Form.Item>
    </Form>
  );
};

const RowStyleSettingsForm = (props: {
  form: FormInstance;
  data: { listData: {}[] };
}) => {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form} autoComplete="off" initialValues={props.data}>
      <FormList name="listData">
        {(fields, { add, remove, move }, { errors }) => (
          <div className="flex flex-col gap-1">
            {map(fields, (field, index) => (
              <div
                className="flex flex-row items-center justify-between"
                key={field.key}
              >
                <div className="text-400">
                  {props.form.getFieldValue(['listData', field.name, 'name'])}
                </div>

                <Space align="start">
                  <FormItem
                    label="顏色"
                    name={[field.name, 'markColor']}
                    rules={[{ required: true, message: '請選擇顏色' }]}
                    style={{ marginBottom: 0 }}
                  >
                    <ColorPicker format="hex" defaultFormat="hex" />
                  </FormItem>

                  <Button
                    icon={<ArrowUpOutlined />}
                    disabled={index === 0}
                    onClick={() => {
                      move(field.name, field.name - 1);
                    }}
                  />

                  <Button
                    icon={<ArrowDownOutlined />}
                    disabled={index === size(fields) - 1}
                    onClick={() => {
                      move(field.name, field.name + 1);
                    }}
                  />
                </Space>
              </div>
            ))}
          </div>
        )}
      </FormList>
    </Form>
  );
};

export function ScheduleMealsComponent(props: ScheduleMealsComponentProps) {
  const [modal, contextHolder] = useModal();
  const [form] = Form.useForm();
  const [noteForm] = Form.useForm();
  const [exportDeliveryMealsForm] = Form.useForm();
  const [rowStyleSettingsForm] = Form.useForm();
  const providerIds = Form.useWatch('providerIds', form);

  const [isMapReady, setIsMapReady] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const { data: { data: providerTypes } = {} } = useProviderTypes();

  const COLORS = useMemo(() => {
    if (providerTypes) {
      return mapValues(keyBy(providerTypes, 'name'), 'color');
    }

    return {};
  }, [providerTypes]);

  const initialValues = {
    providerIds: [],
  };

  const initialNotes = {
    notes: {},
  };

  useEffect(() => {
    noteForm.setFieldsValue({ notes: props.noteData });
  }, [props.noteData, noteForm]);

  const isDisabledCalendarCell = useMemo(() => {
    return isEmpty(providerIds);
  }, [providerIds]);

  const moveItem = (item: {
    dragIndex: number;
    dropIndex: number;
    targetItem: any;
  }) => {
    if (item.dragIndex === null || item.dropIndex === null) {
      return;
    }

    const newListData = cloneDeep(
      props.listData[item.targetItem.locationTagId].data[
        item.targetItem.timestamp
      ]
    );
    const targetIndex = findIndex(
      newListData,
      (findItem) => findItem.rank === item.dragIndex
    );
    const [moveTarget] = newListData.splice(targetIndex, 1);
    newListData.splice(item.dropIndex, 0, moveTarget);
    const newDeliveryMealIds = map(newListData, 'id');

    props.updateScheduleMealsRank({
      locationTagId: item.targetItem.locationTagId,
      time: item.targetItem.timestamp,
      deliveryMealIds: newDeliveryMealIds,
    });
  };

  const renderListData = (
    cellData: Array<schedules.CellData>,
    timestamp: number
  ) => {
    return (
      <ul style={{ padding: 0 }}>
        {!isEmpty(cellData[timestamp]) &&
          map(cellData[timestamp], (item: schedules.CellData) => {
            const isEditable =
              isEditMode &&
              todayTimestamp.isBefore(dayjs(Number(timestamp)).add(1, 'day'));

            return (
              !isEmpty(item) && (
                <ListItemWrapper
                  key={`${item.locationTag.id}.${item.provider.id}.${timestamp}.${item.rank}`}
                  moveItem={moveItem}
                  locationTagId={item.locationTag.id}
                  timestamp={timestamp}
                  rank={item.rank}
                  disabledDrag={isEditable}
                >
                  <Flex component="li" gap="small" align="center">
                    {!isEditable ? (
                      <Badge
                        status={
                          item?.status
                            ? (PROVIDER_STATUS_MAPPING[
                                item.status
                              ] as PresetStatusColorType)
                            : 'default'
                        }
                        text={
                          <span
                            className="p-1"
                            style={{
                              color: COLORS[item.provider.type],
                              backgroundColor: item.needToRecycleBags
                                ? '#ffe58f'
                                : 'unset',
                            }}
                          >
                            <strong style={{ fontSize: 14 }}>
                              {item.provider?.type}
                            </strong>
                            ｜{item.provider?.name}
                          </span>
                        }
                      />
                    ) : (
                      <>
                        <Flex gap="small" align="center">
                          <Select
                            size="small"
                            style={{ width: 43 }}
                            options={PROVIDER_STATUS_OPTIONS}
                            value={item.status}
                            onChange={(value) => {
                              props.updateScheduleMealStatus({
                                id: item.id,
                                status: value,
                              });
                            }}
                          />
                          <span
                            className="p-1"
                            style={{
                              fontSize: 12,
                              textAlign: 'left',
                              color: COLORS[item.provider.type],
                              backgroundColor: item.needToRecycleBags
                                ? '#ffe58f'
                                : 'unset',
                            }}
                          >
                            {`${item.provider?.type}-${item.provider?.name}`}
                          </span>
                        </Flex>

                        {/* 刪除單一供應商 */}
                        <Button
                          icon={<DeleteOutlined />}
                          size="small"
                          onClick={async () => {
                            await props.deleteScheduleMeals([
                              {
                                time: timestamp,
                                locationTagId: item.locationTag.id,
                                providerId: item.provider.id,
                              },
                            ]);
                          }}
                        />
                      </>
                    )}
                  </Flex>
                </ListItemWrapper>
              )
            );
          })}
      </ul>
    );
  };

  const insertListData = async (locationTagId: string, timestamp: number) => {
    if (!isEmpty(providerIds)) {
      const targetProviders = difference(
        providerIds,
        map(props.listData[locationTagId].data[timestamp], 'provider.id')
      );

      const params = map(targetProviders, (providerId: string) => ({
        time: timestamp,
        locationTagId,
        providerId,
      }));

      if (params.length) {
        await props.updateScheduleMeals(params);
      }
    }
  };

  const onExportButtonClick = (startTime: Dayjs, endTime: Dayjs) => {
    modal.confirm({
      cancelText: '取消',
      content: (
        <ExportDeliveryMealsForm
          form={exportDeliveryMealsForm}
          data={{ time: [startTime, endTime] }}
        />
      ),
      icon: null,
      okText: '確認',
      title: '匯出餐點配置',
      onOk: async () => {
        const values = await exportDeliveryMealsForm.validateFields();
        const startTime = values.time[0].startOf('day');
        const endTime = values.time[1].endOf('day');

        return await props.exportDeliveryMeals({
          startTime,
          endTime,
        });
      },
    });
  };

  const onEditRowButtonClick = () => {
    const listData = map(sortedListData, (item, index) => ({
      ...item,
      originIndex: index,
      markColor: new ColorFactory(item.markColor),
    }));
    modal.confirm({
      width: 600,
      cancelText: '取消',
      content: (
        <RowStyleSettingsForm form={rowStyleSettingsForm} data={{ listData }} />
      ),
      icon: null,
      okText: '確認',
      title: '設定排序及顏色',
      onOk: async () => {
        const values = await rowStyleSettingsForm.validateFields();

        forEach(
          values.listData,
          async ({ data, locations, ...item }, index: number) => {
            if (
              index !== sortedListData[item.originIndex].rank ||
              item.markColor.toHexString() !==
                sortedListData[item.originIndex].markColor
            ) {
              await props.updateLocationTag({
                ...item,
                locationIds: map(locations, 'id'),
                markColor: item.markColor.toHexString(),
                rank: index,
              });
            }
          }
        );
      },
    });
  };

  const sortedListData = useMemo(() => {
    if (!isEmpty(props.listData)) {
      return sortBy(values(props.listData), 'rank');
    }
    return [];
  }, [props.listData]);

  return (
    <Space direction="vertical" className="w-full page-schedule-meals">
      {contextHolder}

      <Form form={form} autoComplete="off" initialValues={initialValues}>
        <FormItem
          name="providerIds"
          label="供應商"
          rules={[{ required: true, message: '請選擇供應商' }]}
        >
          <Select
            allowClear
            disabled={!isMapReady}
            mode={'multiple'}
            optionFilterProp="label"
            options={props.providerTagsInformation.options}
            placeholder="請選擇供應商"
            showSearch
            labelRender={(item) =>
              `${props.providerTagsInformation.mappingObject[item.value].type} - ${item.label}`
            }
          />
        </FormItem>
      </Form>

      <MealsMapComponent
        providerIds={providerIds}
        setIsMapReady={setIsMapReady}
        providerTagsInformation={props.providerTagsInformation}
      />
      <Divider />

      <TodayRecycleBags todayDeliveryMeals={props.todayDeliveryMeals} />

      <Divider />
      <Space>
        <Switch
          checkedChildren="編輯模式"
          unCheckedChildren="閱讀模式"
          value={isEditMode}
          onChange={(isChecked) => setIsEditMode(isChecked)}
        />

        <Button
          type="primary"
          size="middle"
          onClick={() => {
            onExportButtonClick(
              props.selectDate,
              props.selectDate.add(11, 'day')
            );
          }}
        >
          匯出餐點配置
        </Button>

        <Button
          type="primary"
          size="middle"
          onClick={onEditRowButtonClick}
          loading={sortedListData.length === 0}
        >
          設定排序及顏色
        </Button>
      </Space>

      <Form form={noteForm} autoComplete="off" initialValues={initialNotes}>
        <Space>
          <CalendarHeader
            value={props.selectDate}
            onChange={(date: Dayjs) => {
              props.setSelectDate(date);
            }}
          />
        </Space>

        <List
          size="small"
          itemLayout="horizontal"
          split={false}
          dataSource={sortedListData}
          renderItem={(locationTagItem) => (
            <>
              <Divider />
              <CalendarCell
                key={locationTagItem.id}
                rowStyleSettings={{ bgColor: locationTagItem.markColor }}
                listData={locationTagItem.data}
                sortedListData={sortedListData}
                locationTagName={locationTagItem.name}
                locationTagId={locationTagItem.id}
                disabled={isDisabledCalendarCell}
                isEditMode={isEditMode}
                onClickAdd={(locationTagId: string, dateTimestamp: number) => {
                  insertListData(locationTagId, dateTimestamp);
                }}
                onCopyParams={(
                  locationTagId: string,
                  dateTimestamp: number
                ) => {
                  form.setFieldValue(
                    'providerIds',
                    map(
                      props.listData[locationTagId].data[dateTimestamp],
                      'provider.id'
                    )
                  );
                  form.validateFields(['providerIds'], { dirty: true });
                  message.info('參數複製成功');
                }}
                onSaveNote={async (
                  locationTagId: string,
                  dateTimestamp: number
                ) => {
                  const note = noteForm.getFieldValue([
                    'notes',
                    dateTimestamp,
                    locationTagId,
                  ]);
                  await props.upateScheduleMealsNote({
                    locationTagId,
                    time: Number(dateTimestamp),
                    note,
                  });
                }}
                onClickDelete={async (values: any) => {
                  await props.deleteScheduleMeals(values);
                }}
                noteData={props.noteData}
                renderListData={renderListData}
                deliveredMeals={props.deliveredMeals}
                modal={modal}
              />
            </>
          )}
        />
      </Form>
    </Space>
  );
}

const ListItemWrapper = (props: {
  moveItem: Function;
  locationTagId: string;
  timestamp: number;
  rank: number;
  disabledDrag: Boolean;
  children: ReactElement<any, string>;
}) => {
  const ref = useRef(null);

  const [, drag] = useDrag({
    type: 'LIST_ITEM',
    item: {
      locationTagId: props.locationTagId,
      timestamp: props.timestamp,
      rank: props.rank,
    },
    canDrag: () => !!props.disabledDrag,
    end: (dropResult, monitor) => {
      const dragItem = monitor.getItem();
      const didDrop = monitor.didDrop();

      if (didDrop) {
        const dropTarget: {
          locationTagId: string;
          timestamp: number;
          rank: number;
        } | null = monitor.getDropResult();

        props.moveItem({
          dragIndex: dragItem.rank,
          dropIndex: dropTarget?.rank,
          targetItem: dragItem,
        });
      }
    },
  });

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: 'LIST_ITEM',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: (dragItem: {
      locationTagId: string;
      timestamp: number;
      rank: number;
    }) => {
      return (
        dragItem.timestamp === props.timestamp && dragItem.rank !== props.rank
      );
    },
    hover: (dragItem: any, monitor) => {
      const dragIndex = dragItem.rank;
      const dropIndex = props.rank;

      if (dragIndex === dropIndex || props.timestamp !== dragItem.timestamp) {
        return;
      }
    },
    drop: () => ({
      locationTagId: props.locationTagId,
      timestamp: props.timestamp,
      rank: props.rank,
    }),
  }));

  drag(drop(ref));

  return (
    <Flex ref={ref} vertical>
      {props.children}
      {isOver && canDrop && <Skeleton.Input block />}
    </Flex>
  );
};

const TodayRecycleBags = memo(
  (props: { todayDeliveryMeals: DeliveryMeals }) => {
    const recycleBags = useMemo(
      () =>
        uniqBy(
          filter(props.todayDeliveryMeals, (item) => item.needToRecycleBags) ||
            [],
          'provider.id'
        ),
      [props.todayDeliveryMeals]
    );

    return (
      <Typography.Text>
        <>
          今日回收店家：
          {recycleBags.length
            ? map(recycleBags, (item) => item.provider.name).join('、')
            : '無'}
        </>
      </Typography.Text>
    );
  }
);
