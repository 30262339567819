import { useState } from 'react';
import { ProductsComponent } from './products.component';
import { usePagination } from '../shared/usePagination';
import { FilterValue } from 'antd/es/table/interface';
import {
  useDeleteProduct,
  useExportProducts,
  useProducts,
  useUpdateProduct,
} from '../utils/api.hook';

export function ProductsContainer() {
  const [keyword, setKeyword] = useState<string>('');
  const [filters, setFilters] =
    useState<Record<string, FilterValue | null | boolean>>();
  const pagination = usePagination();
  const { mutateAsync: deleteProduct, isLoading: isDeleting } =
    useDeleteProduct();
  const {
    data: { products, providerFilters, total } = {},
    isFetching,
    isLoading,
  } = useProducts({
    ...filters,
    name: keyword,
    take: pagination.take,
    skip: pagination.skip,
  });
  const { mutateAsync: updateProduct, isLoading: isUpdating } =
    useUpdateProduct();
  const { refetch: exportProducts } = useExportProducts();

  return (
    <ProductsComponent
      exportProducts={exportProducts}
      deleteProduct={deleteProduct}
      updateProduct={updateProduct}
      isLoading={isFetching || isLoading || isDeleting || isUpdating}
      products={products || []}
      providerFilters={providerFilters || []}
      setFilters={setFilters}
      setKeyword={setKeyword}
      pagination={{
        ...pagination,
        total,
      }}
    />
  );
}
