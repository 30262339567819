import { useEffect, useState } from 'react';
import { groupBy, isEmpty, map, uniq } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { FilterValue } from 'antd/es/table/interface';

import { usePagination } from '../../shared/usePagination';
import { useScheduleMeals, useProviders } from '../../utils/api.hook';

import { ScheduleOverviewComponent } from './schedule-provider-overview.component';
import schedules from '../schedules';

const DEFAULT_RANGE = {
  startTime: dayjs().subtract(6, 'week').day(0),
  endTime: dayjs().day(6),
};

export function ScheduleProviderOverviewContainer() {
  const [keyword, setKeyword] = useState<string>('');
  const [filters, setFilters] = useState<Record<string, FilterValue>>({});
  const pagination = usePagination({
    defaultPageSize: 15,
  });
  const [listData, setListData] = useState<
    | []
    | ({
        lastPickTime?: number;
        pickedTime: number[];
      } & schedules.Provider)[]
  >([]);
  const [timeRange, setTimeRange] = useState<{
    startTime: Dayjs;
    endTime: Dayjs;
  }>({
    startTime: DEFAULT_RANGE.startTime,
    endTime: DEFAULT_RANGE.endTime,
  });

  const {
    data: { providers, total } = {},
    isLoading,
    isFetching,
  } = useProviders({
    keyword,
    ...filters,
  });
  const {
    data: { deliveryMeals = [] } = {},
    isLoading: isLoadingScheduleMeals,
  } = useScheduleMeals({
    startTime: timeRange.startTime.valueOf(),
    endTime: timeRange.endTime.valueOf(),
  });

  useEffect(() => {
    const initListData = async () => {
      const mappingGroupByProvider = groupBy(deliveryMeals, 'provider.id');

      const initData = map(providers, (item: schedules.Provider) => ({
        ...item,
        pickedTime: !isEmpty(mappingGroupByProvider[item.id])
          ? uniq(map(mappingGroupByProvider[item.id], 'time'))
          : [],
      }));

      setListData(initData);
    };

    if (
      timeRange.startTime.isValid() &&
      timeRange.endTime.isValid() &&
      !isEmpty(providers)
    ) {
      initListData();
    }
  }, [deliveryMeals, timeRange, providers]);

  return (
    <ScheduleOverviewComponent
      listData={listData}
      isLoading={isLoadingScheduleMeals || isLoading || isFetching}
      changeTimeRange={setTimeRange}
      // timeRange={timeRange}
      deafultRange={DEFAULT_RANGE}
      timeRange={timeRange}
      setKeyword={setKeyword}
      setFilters={setFilters}
      pagination={{
        ...pagination,
        total,
      }}
    />
  );
}
