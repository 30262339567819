import {
  memo,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {
  Form,
  Space,
  Badge,
  Select,
  Button,
  Divider,
  Switch,
  Flex,
  Skeleton,
  List,
  Typography,
  message,
  Card,
  Row,
  Col,
} from 'antd';
import { PresetStatusColorType } from 'antd/es/_util/colors';
import useModal from 'antd/es/modal/useModal';
import FormItem from 'antd/es/form/FormItem';
import {
  DeleteOutlined,
  SearchOutlined,
  AppstoreAddOutlined,
  ClearOutlined,
  RedoOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  VerticalAlignTopOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import {
  cloneDeep,
  difference,
  filter,
  findIndex,
  forEach,
  isEmpty,
  keyBy,
  map,
  mapValues,
  sortBy,
  uniq,
  uniqBy,
  values,
} from 'lodash';

import {
  PROVIDER_STATUS_MAPPING,
  PROVIDER_STATUS_TYPE,
} from '../../constants/schedule-meals.types';
import schedules from '../schedules';

import { CalendarHeader, CalendarCell } from './menus-calendar.component';
import { useProviderTypes } from '../../utils/api-hooks/provider.hook';
import { ColorFactory } from 'antd/es/color-picker/color';
import { ExportMenusForm } from './export-menus-form.component';
import { RowStyleForm } from './row-style-form.component';

const todayTimestamp = dayjs().startOf('day');

const PROVIDER_STATUS_OPTIONS = [
  {
    label: (
      <Badge
        status={
          PROVIDER_STATUS_MAPPING[
            PROVIDER_STATUS_TYPE.PENDING
          ] as PresetStatusColorType
        }
      />
    ),
    value: PROVIDER_STATUS_TYPE.PENDING,
  },
  {
    label: (
      <Badge
        status={
          PROVIDER_STATUS_MAPPING[
            PROVIDER_STATUS_TYPE.CONFIRMED
          ] as PresetStatusColorType
        }
      />
    ),
    value: PROVIDER_STATUS_TYPE.CONFIRMED,
  },
  {
    label: (
      <Badge
        status={
          PROVIDER_STATUS_MAPPING[
            PROVIDER_STATUS_TYPE.REJECTED
          ] as PresetStatusColorType
        }
      />
    ),
    value: PROVIDER_STATUS_TYPE.REJECTED,
  },
];

type DeliveryMeals = Array<{
  id: string;
  time: number;
  provider: schedules.Provider;
  locationTag: schedules.LocationTag;
  driver: schedules.Driver;
  rank: number;
  needToRecycleBags: boolean;
  status:
    | PROVIDER_STATUS_TYPE.PENDING
    | PROVIDER_STATUS_TYPE.CONFIRMED
    | PROVIDER_STATUS_TYPE.REJECTED;
}>;

type ScheduleMenusComponentProps = {
  locationTags: Array<schedules.LocationTag>;
  providerTagsInformation: {
    options: { label: string; options: { label: string; value: string }[] }[];
    mappingObject: {
      [key: string]: {
        idNumber: string;
        name: string;
        rank: number;
        type: string;
        address: string;
      };
    };
  };
  updateScheduleMeals: Function;
  deleteScheduleMeals: Function;
  updateScheduleMealStatus: Function;
  updateScheduleMealsRank: Function;
  upateScheduleMealsNote: Function;
  exportDeliveryMeals: Function;
  updateLocationTag: (values: {
    id: string;
    name: string;
    description: string;
    textColor: string;
    backgroundColor: string;
    pay: number;
  }) => Promise<any>;
  selectDate: Dayjs;
  setSelectDate: Function;
  listData: Record<
    string,
    {
      id: string;
      name: string;
      rank: number;
      markColor: string;
      data: schedules.MappingGroupByTimestamp;
    }
  >;
  noteData: { [time: number]: { [locationTagId: string]: string } };
  todayDeliveryMeals: DeliveryMeals;
  deliveredMeals: any;
  suggestDeliveryMeals: { providerIds: string[] }[];
  isSuggestionDeliveryMealsLoading: boolean;
  refetchSuggestDeliveryMeals: Function;
};

export function ScheduleMenusComponent(props: ScheduleMenusComponentProps) {
  const refPage = useRef<HTMLDivElement>(null);
  const [modal, contextHolder] = useModal();
  const [form] = Form.useForm();
  const [noteForm] = Form.useForm();
  const [exportDeliveryMealsForm] = Form.useForm();
  const [rowStyleSettingsForm] = Form.useForm();
  const providerIds = Form.useWatch('providerIds', form);
  const [previewData, setPreviewData] = useState<
    { id: string; rank: number }[]
  >([]);

  const [isShowPreview, setIsShowPreview] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const { data: { data: providerTypes } = {} } = useProviderTypes();

  const COLORS = useMemo(() => {
    if (providerTypes) {
      return mapValues(keyBy(providerTypes, 'name'), 'color');
    }

    return {};
  }, [providerTypes]);

  const initialValues = {
    providerIds: [],
  };

  const initialNotes = {
    notes: {},
  };

  useEffect(() => {
    noteForm.setFieldsValue({ notes: props.noteData });
  }, [props.noteData, noteForm]);

  const movePreviewItem = (item: {
    dragIndex: number;
    dropIndex: number;
    targetItem: any;
  }) => {
    if (item.dragIndex === null || item.dropIndex === null) {
      return;
    }

    const newListData = cloneDeep(previewData);
    const targetIndex = findIndex(
      newListData,
      (findItem) => findItem.rank === item.dragIndex
    );
    const moveTarget = newListData?.splice(targetIndex, 1)[0];
    if (moveTarget) {
      newListData?.splice(item.dropIndex, 0, moveTarget);
    }
    const newDeliveryMealIds = map(newListData, (item, index) => ({
      ...item,
      rank: index,
    }));

    setPreviewData(newDeliveryMealIds);
  };

  const renderListData = (
    cellData: Array<schedules.CellData>,
    timestamp: number
  ) => {
    return (
      <ul style={{ padding: 0 }}>
        {!isEmpty(cellData[timestamp]) &&
          map(cellData[timestamp], (item: schedules.CellData) => {
            const isEditable =
              isEditMode &&
              todayTimestamp.isBefore(dayjs(Number(timestamp)).add(1, 'day'));

            return (
              !isEmpty(item) && (
                <Flex
                  component="li"
                  gap="small"
                  align="center"
                  key={`${item.locationTag.id}.${item.provider.id}.${timestamp}.${item.rank}`}
                >
                  {!isEditable ? (
                    <Badge
                      status={
                        item?.status
                          ? (PROVIDER_STATUS_MAPPING[
                              item.status
                            ] as PresetStatusColorType)
                          : 'default'
                      }
                      text={
                        <span
                          className="p-1"
                          style={{
                            color: COLORS[item.provider.type],
                            backgroundColor: item.needToRecycleBags
                              ? '#ffe58f'
                              : '#ffffff',
                          }}
                        >
                          <strong style={{ fontSize: 14 }}>
                            {item.provider?.type}
                          </strong>
                          ｜{item.provider?.name}
                        </span>
                      }
                    />
                  ) : (
                    <>
                      <Flex gap="small" align="center">
                        <Select
                          size="small"
                          style={{ width: 43 }}
                          options={PROVIDER_STATUS_OPTIONS}
                          value={item.status}
                          onChange={(value) => {
                            props.updateScheduleMealStatus({
                              id: item.id,
                              status: value,
                            });
                          }}
                        />
                        <span
                          className="p-1"
                          style={{
                            fontSize: 12,
                            textAlign: 'left',
                            color: COLORS[item.provider.type],
                            backgroundColor: item.needToRecycleBags
                              ? '#ffe58f'
                              : 'unset',
                          }}
                        >
                          {`${item.provider?.type}-${item.provider?.name}`}
                        </span>
                      </Flex>
                    </>
                  )}
                </Flex>
              )
            );
          })}
      </ul>
    );
  };

  const insertListData = async (locationTagId: string, timestamp: number) => {
    if (!isEmpty(previewData)) {
      const targetProviders = difference(
        map(previewData, 'id'),
        map(props.listData[locationTagId].data[timestamp], 'provider.id')
      );

      const params = map(targetProviders, (providerId: string) => ({
        time: timestamp,
        locationTagId,
        providerId,
      }));

      if (params.length) {
        await props.updateScheduleMeals(params);
      }
    }
  };

  const onExportButtonClick = (startTime: Dayjs, endTime: Dayjs) => {
    modal.confirm({
      cancelText: '取消',
      content: (
        <ExportMenusForm
          form={exportDeliveryMealsForm}
          data={{ time: [startTime, endTime] }}
        />
      ),
      icon: null,
      okText: '確認',
      title: '匯出餐點配置',
      onOk: async () => {
        const values = await exportDeliveryMealsForm.validateFields();
        const startTime = values.time[0].startOf('day');
        const endTime = values.time[1].endOf('day');

        return await props.exportDeliveryMeals({
          startTime,
          endTime,
        });
      },
    });
  };

  const onEditRowButtonClick = () => {
    const listData = map(sortedListData, (item, index) => ({
      ...item,
      originIndex: index,
      markColor: new ColorFactory(item.markColor),
    }));
    modal.confirm({
      width: 600,
      cancelText: '取消',
      content: <RowStyleForm form={rowStyleSettingsForm} data={{ listData }} />,
      icon: null,
      okText: '確認',
      title: '設定排序及顏色',
      onOk: async () => {
        const values = await rowStyleSettingsForm.validateFields();

        forEach(
          values.listData,
          async ({ data, locations, ...item }, index: number) => {
            if (
              index !== sortedListData[item.originIndex].rank ||
              item.markColor.toHexString() !==
                sortedListData[item.originIndex].markColor
            ) {
              await props.updateLocationTag({
                ...item,
                locationIds: map(locations, 'id'),
                markColor: item.markColor.toHexString(),
                rank: index,
              });
            }
          }
        );
      },
    });
  };

  const sortedListData = useMemo(() => {
    if (!isEmpty(props.listData)) {
      return sortBy(values(props.listData), 'rank');
    }
    return [];
  }, [props.listData]);

  return (
    <Space
      direction="vertical"
      className="w-full h-full page-schedule-meals"
      style={{ overflowX: 'hidden', overflowY: 'auto' }}
      ref={refPage}
    >
      {contextHolder}

      <Form form={form} autoComplete="off" initialValues={initialValues}>
        <FormItem
          name="providerIds"
          label="供應商"
          rules={[{ required: true, message: '請選擇供應商' }]}
          help={
            <Space className="mt-2">
              <Button
                icon={<AppstoreAddOutlined />}
                size="small"
                type="primary"
                onClick={async (e) => {
                  e.stopPropagation();

                  const providerIds = form.getFieldValue('providerIds');

                  if (!isEmpty(providerIds)) {
                    const newPreviewData = uniq(
                      map(previewData, 'id').concat(providerIds)
                    );
                    const params = map(
                      newPreviewData,
                      (providerId: string, index) => ({
                        id: providerId,
                        rank: index,
                      })
                    );

                    if (params.length) {
                      setPreviewData(params);
                      message.info('已新增，可以去調整順序及確認內容囉！');
                    }
                  }
                }}
              >
                新增至預覽小卡
              </Button>

              <Button
                icon={<SearchOutlined />}
                className="button-to-maps"
                size="small"
                type="link"
                href={`https://www.google.com.tw/maps/dir/${map(providerIds, (providerId) => props.providerTagsInformation.mappingObject[providerId].address).join('/')}`}
                target="_blank"
                disabled={!providerIds?.length}
              >
                在Google Maps上查看更多細節
              </Button>
            </Space>
          }
        >
          <Select
            allowClear
            mode={'multiple'}
            optionFilterProp="label"
            options={props.providerTagsInformation.options}
            placeholder="請選擇供應商"
            showSearch
            labelRender={(item) =>
              `${props.providerTagsInformation.mappingObject[item.value].type} - ${item.label}`
            }
          />
        </FormItem>
      </Form>

      <Divider />

      <Typography.Title level={5}>
        隨機推薦供應商
        <Button
          className="ml-2"
          icon={<RedoOutlined />}
          loading={props.isSuggestionDeliveryMealsLoading}
          onClick={async (e) => {
            e.stopPropagation();

            props.refetchSuggestDeliveryMeals();
          }}
        >
          刷新
        </Button>
      </Typography.Title>
      <Row gutter={[8, 8]}>
        {map(props.suggestDeliveryMeals, ({ providerIds }, index) => (
          <Col key={index}>
            <Card
              style={{ width: 200 }}
              size="small"
              loading={props.isSuggestionDeliveryMealsLoading}
              actions={[
                <Button
                  icon={<AppstoreAddOutlined />}
                  size="small"
                  type="text"
                  disabled={props.isSuggestionDeliveryMealsLoading}
                  onClick={async (e) => {
                    e.stopPropagation();

                    if (!isEmpty(providerIds)) {
                      const newPreviewData = uniq(
                        map(previewData, 'id').concat(providerIds)
                      );
                      const params = map(
                        newPreviewData,
                        (providerId: string, index) => ({
                          id: providerId,
                          rank: index,
                        })
                      );

                      if (params.length) {
                        setPreviewData(params);
                        message.info('已新增，可以去調整順序及確認內容囉！');
                      }
                    }
                  }}
                >
                  新增至預覽小卡
                </Button>,
              ]}
            >
              {map(providerIds, (id) => {
                const item = props.providerTagsInformation.mappingObject[id];

                return (
                  <Flex
                    key={id}
                    component="li"
                    gap="small"
                    align="center"
                    justify="space-between"
                  >
                    <span
                      className="p-1"
                      style={{
                        color: COLORS[item.type],
                      }}
                    >
                      <strong style={{ fontSize: 14 }}>{item.type}</strong>｜
                      {item.name}
                    </span>
                  </Flex>
                );
              })}
            </Card>
          </Col>
        ))}
      </Row>

      <Divider />

      <TodayRecycleBags todayDeliveryMeals={props.todayDeliveryMeals} />

      <Divider />

      <Card
        style={{
          position: 'fixed',
          top: 120,
          zIndex: 10,
        }}
        size="small"
        className="right-0"
      >
        <Row gutter={8}>
          <Col>
            <Space direction="vertical">
              <Button
                icon={<VerticalAlignTopOutlined />}
                type="text"
                onClick={() => {
                  refPage.current?.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  });
                }}
              />

              <Button
                icon={
                  isShowPreview ? (
                    <RightCircleOutlined />
                  ) : (
                    <LeftCircleOutlined />
                  )
                }
                type="text"
                onClick={async () => {
                  setIsShowPreview(!isShowPreview);
                }}
              />
            </Space>
          </Col>
          <Col
            style={{
              display: isShowPreview ? 'flex' : 'none',
            }}
          >
            <Card
              style={{ width: 250 }}
              actions={[
                <Button
                  icon={<ClearOutlined />}
                  size="small"
                  type="text"
                  disabled={!previewData.length}
                  onClick={async (e) => {
                    e.stopPropagation();

                    setPreviewData([]);
                    message.info('已清除，可重新安排囉！');
                  }}
                >
                  清空
                </Button>,
              ]}
            >
              <Card.Meta
                className="pb-2"
                title="預覽小卡"
                description="請先在此確認完順序及內容"
              />
              {map(previewData, ({ id, rank }, index) => {
                const item = props.providerTagsInformation.mappingObject[id];

                return (
                  <PreviewItemWrapper
                    key={`preview-${id}-${rank}`}
                    moveItem={movePreviewItem}
                    rank={rank}
                    disabledDrag={false}
                  >
                    <Flex
                      component="li"
                      gap="small"
                      align="center"
                      justify="space-between"
                    >
                      <span
                        className="p-1"
                        style={{
                          color: COLORS[item.type],
                        }}
                      >
                        <strong style={{ fontSize: 14 }}>{item.type}</strong>｜
                        {item.name}
                      </span>

                      {/* 刪除單一供應商 */}
                      <Button
                        icon={<DeleteOutlined />}
                        size="small"
                        onClick={async () => {
                          setPreviewData((previewData) => {
                            const newPreviewData = cloneDeep(previewData);
                            newPreviewData?.splice(index, 1);
                            return newPreviewData;
                          });
                        }}
                      />
                    </Flex>
                  </PreviewItemWrapper>
                );
              })}
            </Card>
          </Col>

          <Col
            style={{
              display: isShowPreview ? 'flex' : 'none',
            }}
          >
            <Space direction="vertical">
              <Switch
                checkedChildren="編輯模式"
                unCheckedChildren="閱讀模式"
                value={isEditMode}
                onChange={(isChecked) => setIsEditMode(isChecked)}
              />

              <Button
                type="primary"
                size="small"
                onClick={() => {
                  onExportButtonClick(
                    props.selectDate,
                    props.selectDate.add(11, 'day')
                  );
                }}
              >
                匯出餐點配置
              </Button>

              <Button
                type="primary"
                size="small"
                onClick={onEditRowButtonClick}
                loading={sortedListData.length === 0}
              >
                設定排序及顏色
              </Button>

              <CalendarHeader
                value={props.selectDate}
                onChange={(date: Dayjs) => {
                  props.setSelectDate(date);
                }}
              />
            </Space>
          </Col>
        </Row>
      </Card>

      <Form form={noteForm} autoComplete="off" initialValues={initialNotes}>
        <List
          size="small"
          itemLayout="horizontal"
          split={false}
          dataSource={sortedListData}
          renderItem={(locationTagItem) => (
            <>
              <Divider />
              <CalendarCell
                key={locationTagItem.id}
                rowStyleSettings={{ bgColor: locationTagItem.markColor }}
                listData={locationTagItem.data}
                sortedListData={sortedListData}
                locationTagName={locationTagItem.name}
                locationTagId={locationTagItem.id}
                disabled={!previewData.length}
                isEditMode={isEditMode}
                onClickAdd={(locationTagId: string, dateTimestamp: number) => {
                  insertListData(locationTagId, dateTimestamp);
                }}
                onCopyParams={(
                  locationTagId: string,
                  dateTimestamp: number
                ) => {
                  const newPreviewData = uniq(
                    map(previewData, 'id').concat(
                      map(
                        props.listData[locationTagId].data[dateTimestamp],
                        'provider.id'
                      )
                    )
                  );

                  const params = map(
                    newPreviewData,
                    (providerId: string, index) => ({
                      id: providerId,
                      rank: index,
                    })
                  );

                  if (params.length) {
                    setPreviewData(params);
                    message.info('參數已複製到預覽小卡了！');
                  }
                }}
                onSaveNote={async (
                  locationTagId: string,
                  dateTimestamp: number
                ) => {
                  const note = noteForm.getFieldValue([
                    'notes',
                    dateTimestamp,
                    locationTagId,
                  ]);
                  await props.upateScheduleMealsNote({
                    locationTagId,
                    time: Number(dateTimestamp),
                    note,
                  });
                }}
                onClickDelete={async (values: any) => {
                  await props.deleteScheduleMeals(values);
                }}
                noteData={props.noteData}
                renderListData={renderListData}
                deliveredMeals={props.deliveredMeals}
                modal={modal}
              />
            </>
          )}
        />
      </Form>
    </Space>
  );
}

const TodayRecycleBags = memo(
  (props: { todayDeliveryMeals: DeliveryMeals }) => {
    const recycleBags = useMemo(
      () =>
        uniqBy(
          filter(props.todayDeliveryMeals, (item) => item.needToRecycleBags) ||
            [],
          'provider.id'
        ),
      [props.todayDeliveryMeals]
    );

    return (
      <Typography.Text>
        <>
          今日回收店家：
          {recycleBags.length
            ? map(recycleBags, (item) => item.provider.name).join('、')
            : '無'}
        </>
      </Typography.Text>
    );
  }
);

const PreviewItemWrapper = (props: {
  moveItem: Function;
  rank: number;
  disabledDrag: Boolean;
  children: ReactElement<any, string>;
}) => {
  const ref = useRef(null);

  const [, drag] = useDrag({
    type: 'PREVIEW_ITEM',
    item: {
      rank: props.rank,
    },
    canDrag: () => !props.disabledDrag,
    end: (dropResult, monitor) => {
      const dragItem = monitor.getItem();
      const didDrop = monitor.didDrop();

      if (didDrop) {
        const dropTarget: {
          rank: number;
        } | null = monitor.getDropResult();

        props.moveItem({
          dragIndex: dragItem.rank,
          dropIndex: dropTarget?.rank,
          targetItem: dragItem,
        });
      }
    },
  });

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: 'PREVIEW_ITEM',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: (dragItem: { rank: number }) => {
      return dragItem.rank !== props.rank;
    },
    hover: (dragItem: any, monitor) => {
      const dragIndex = dragItem.rank;
      const dropIndex = props.rank;

      if (dragIndex === dropIndex) {
        return;
      }
    },
    drop: () => ({
      rank: props.rank,
    }),
  }));

  drag(drop(ref));

  return (
    <Flex ref={ref} vertical>
      {props.children}
      {isOver && canDrop && <Skeleton.Input block />}
    </Flex>
  );
};
