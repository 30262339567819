import { Button, Space, Table, Tag } from 'antd';
import { useForm } from 'antd/es/form/Form';
import useModal from 'antd/es/modal/useModal';
import { ColumnType } from 'antd/es/table';
import { ProviderTagForm } from './provider-tag-form.component';
import { map, orderBy } from 'lodash';

type Props = {
  isLoading: boolean;
  providerTags: {
    id: string;
    name: string;
    description: string;
    providers: {
      name: string;
      idNumber: string;
      id: string;
    }[];
  }[];
  createProviderTag: (values: {
    name: string;
    description: string;
  }) => Promise<any>;
  updateProviderTag: (values: {
    id: string;
    name: string;
    description: string;
  }) => Promise<any>;
  deleteProviderTag: (id: string) => Promise<any>;
};

export function ProvidersTagsComponent(props: Props) {
  const [form] = useForm<{
    name: string;
    description: string;
    providerIds: string[];
  }>();
  const [modal, contextHolder] = useModal();

  const onCreateButtonClick = () => {
    modal.confirm({
      cancelText: '取消',
      content: <ProviderTagForm form={form} />,
      icon: null,
      okText: '確認',
      title: '新增供應商標籤',
      onOk: async () => {
        const values = await form.validateFields();

        return props.createProviderTag(values);
      },
    });
  };

  const onEditButtonClick = (data: Props['providerTags'][number]) => {
    modal.confirm({
      cancelText: '取消',
      content: (
        <ProviderTagForm
          form={form}
          data={{
            ...data,
            providerIds: map(
              orderBy(data.providers, ['idNumber'], ['asc']),
              'id'
            ),
          }}
        />
      ),
      icon: null,
      okText: '確認',
      title: '編輯供應商標籤',
      width: '80%',
      onOk: async () => {
        const values = await form.validateFields();

        return props.updateProviderTag({
          id: data.id,
          ...values,
        });
      },
    });
  };

  const onDeleteButtonClick = (id: string) => {
    modal.confirm({
      cancelText: '取消',
      content: '確定要刪除嗎？',
      icon: null,
      okText: '確認',
      title: '刪除供應商標籤',
      onOk: async () => {
        return props.deleteProviderTag(id);
      },
    });
  };

  const columns: ColumnType<Props['providerTags'][number]>[] = [
    {
      title: '名稱',
      dataIndex: 'name',
      width: 150,
    },
    {
      title: '敘述',
      dataIndex: 'description',
      width: 150,
    },
    {
      title: '套用的供應商',
      dataIndex: 'providers',
      render: (providers) => {
        return (
          <div className="flex flex-wrap gap-2">
            {map(orderBy(providers, ['idNumber'], ['asc']), (provider) => {
              return (
                <Tag key={provider.id}>
                  {provider.idNumber} - {provider.name}
                </Tag>
              );
            })}
          </div>
        );
      },
    },
    {
      title: '操作',
      width: 100,
      render: (record) => (
        <Space>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              onEditButtonClick(record);
            }}
          >
            編輯
          </Button>
          <Button
            danger
            size="small"
            onClick={() => {
              onDeleteButtonClick(record.id);
            }}
          >
            刪除
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Space className="w-full" direction="vertical">
      {contextHolder}
      <div className="text-2xl font-bold">供應商標籤</div>
      <Table
        columns={columns}
        loading={props.isLoading}
        size="small"
        rowKey="id"
        title={() => (
          <Button type="primary" onClick={onCreateButtonClick}>
            建立標籤
          </Button>
        )}
        dataSource={props.providerTags || []}
      ></Table>
    </Space>
  );
}
