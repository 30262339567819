import { map } from 'lodash';
import { usePagination } from '../../shared/usePagination';
import {
  useCreateReceivable,
  useDeleteReceivable,
  useReceivables,
  useUpdateReceivable,
} from '../../utils/api.hook';
import ReceivablesComponent from './receivables.component';
import { useState } from 'react';
import { FilterValue } from 'antd/es/table/interface';

export function ReceivablesContainer() {
  const pagination = usePagination();
  const [filters, setFilters] = useState<Record<string, FilterValue | null>>(
    {}
  );
  const { mutateAsync: createReceivable, isLoading: isCreating } =
    useCreateReceivable();
  const { mutateAsync: updateReceivable, isLoading: isUpdating } =
    useUpdateReceivable();
  const { mutateAsync: deleteReceivable, isLoading: isDeleting } =
    useDeleteReceivable();
  const {
    data: { data = [], total = 0, providerFilters = [] } = {},
    isLoading,
  } = useReceivables({
    ...filters,
    skip: pagination.skip,
    take: pagination.take,
  });

  return (
    <ReceivablesComponent
      createReceivable={createReceivable}
      deleteReceivable={deleteReceivable}
      receivables={data}
      setFilters={setFilters}
      updateReceivable={updateReceivable}
      providerFilters={map(providerFilters, (provider) => ({
        text: `${provider.idNumber} - ${provider.name}`,
        value: provider.id,
      }))}
      pagination={{
        ...pagination,
        total,
      }}
      isLoading={isLoading || isCreating || isUpdating || isDeleting}
    />
  );
}
